import React, { FC } from "react";
import styles from "./progress.bar.module.scss";

interface OwnToProps {
    current: number;
    total: number;
}

const ProgressBar: FC<OwnToProps> = ({ current, total }) => {
    const percentage = total ? ((current / total) * 100).toFixed(2) : 0;

    return (
        <div className={styles.progressBarContainer}>
            <div className={styles.progressBar}>
                <div
                    className={styles.progress}
                    style={{
                        width: `${percentage}%`,
                        background: getColor(+percentage),
                    }}
                ></div>
            </div>
        </div>
    );
};

const getColor = (percentage: number) => {
    if (percentage < 30)
        return "linear-gradient(42.61deg, rgba(255, 59, 48, 0.2) 0%,rgb(255, 59, 48) 91.848%)";
    if (percentage < 50)
        return "linear-gradient(43.83deg, rgba(255, 153, 34, 0.2) 0%,rgb(255, 153, 34) 96.375%)";
    if (percentage < 99)
        return "linear-gradient(44.13deg, rgb(201, 255, 198) 0%,rgb(0, 155, 91) 97.063%)";
    return "#009B5B";
};

export default ProgressBar;
