import React, { FC } from "react";
import styles from "./tier.item.module.scss";
import tierIcon from "./../../assets/images/icon/tier-icon.svg";
import polygon from "./../../assets/images/logo/polygon.svg";
import nodeIcon from "./../../assets/images/icon/node-icon.svg";
import { CustomButton, ProgressBar } from "../index";
import { formatToEth } from "../../pages/one-tier-page";

interface OwnToProps {
    start_sale: string;
    end_sale: string;
    price_native_token: string;
    limit_emission: string;
    sale_limit: string;
    id: string;
    isConnected?: boolean;
}

const TierItem: FC<OwnToProps> = ({
    sale_limit,
    limit_emission,
    start_sale,
    end_sale,
    price_native_token,
    id,
    isConnected,
}) => {
    const isSaleNotStarted =
        new Date(start_sale).getTime() > new Date().getTime();
    const isSaleEnded =
        new Date(end_sale).getTime() < new Date().getTime() ||
        sale_limit == limit_emission;
    const progress = ((+sale_limit / +limit_emission) * 100).toFixed(2);
    const buttonText = isSaleNotStarted
        ? `Sale Starts ${start_sale}`
        : isSaleEnded
        ? "Sold Out"
        : "Purchase";
    const buttonBgColor =
        isSaleNotStarted || isSaleEnded ? "#DCDEDC" : "#009B5B";

    return (
        <div className={styles.tierContainer}>
            <div className={styles.tierContent}>
                <div className={styles.tierHeader}>
                    <div className={styles.tierId}>
                        <img src={tierIcon} alt="tier icon" />
                        <span>tier {id}</span>
                    </div>
                    <div>
                        <img src={polygon} alt="polygon logo" />
                    </div>
                </div>
                <div className={styles.tierInfo}>
                    <img src={polygon} alt="polygon logo" />
                    <h2>
                        {formatToEth(+price_native_token)} <span>ETH</span>
                    </h2>
                    <div className={styles.nodeContainer}>
                        <p>/ per</p>
                        <img src={nodeIcon} alt="node icon" />
                        <p> Node</p>
                    </div>
                    <div className={styles.valueContainer}>
                        <p>Nodes Sold</p>
                        <ProgressBar
                            total={+limit_emission}
                            current={+sale_limit}
                        />
                        <div className={styles.valueContainerItem}>
                            <p>
                                <span>{sale_limit}</span> / {limit_emission}
                            </p>
                            <p className={styles.interest}>{progress}%</p>
                        </div>
                    </div>
                    <div className={styles.btnContainer}>
                        <CustomButton
                            text={buttonText}
                            backgroundColor={buttonBgColor}
                            type="link"
                            isDisable={isSaleEnded || isSaleNotStarted}
                            color="#FFF"
                            padding={"14px 4px"}
                            url={
                                isConnected ? `/tiers/${id}` : `/connect-wallet`
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TierItem;
