import {AuthService} from "./auth.service";

export class TiersService {

    private AuthService;

    constructor() {
        this.AuthService = new AuthService();
    }
    async getAllTiers(): Promise<any> {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL_NODE_SALE}/tiers/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!res.ok) {
            throw new Error('Failed.');
        }
        return await res.json();
    }

    async getTierById(id: string){
        const res = await fetch(`${process.env.REACT_APP_BASE_URL_NODE_SALE}/tiers/${id}`);
        if (!res.ok) {
            throw new Error('Failed.');
        }
        return await res.json();
    }

    async getPrivateSalePermission(){
        const token = await this.AuthService.getAuthToken();
        const res = await fetch(`${process.env.REACT_APP_BASE_URL_NODE_SALE}/private/sale/permission`, {
            headers: {
                bearer: token as string
            }
        });
        if (!res.ok) {
            throw new Error('Failed.');
        }
        return await res.json();
    }
}

export type Tier = {
    end_sale: string;
    end_sale_ts: Date;
    id: string;
    instant_mint: boolean;
    limit_emission: string;
    price_native_token: string;
    public_sale_available: boolean;
    sale_limit: string;
    start_sale: string;
    start_sale_ts: Date;
}
