import React, { FC, useState } from "react";
import styles from "./custom.input.module.scss";
import nodeIcon from "./../../assets/images/icon/node-icon.svg";
import polygon from "./../../assets/images/logo/polygon.svg";
import arrowBottom from "../../assets/images/icon/arrow-bottom.svg";
import not from "../../assets/images/icon/not.svg";
import { AddressInput } from "../index";

interface OwnToProps {
    value: string;
    purchaseValue?: string;
    setValue: (p: string) => void;
    purchaseSetValue?: (p: string) => void;
    limit?: (x: number) => number;
    label?: string;
    icon?: string;
    placeholder?: string;
    type: string;
    currency?: boolean;
    nodeValue?: number;
    arrowBottomIcon?: boolean;
    purchaseInput?: boolean;
    min?: string;
    half?: string;
    max?: string;
    token?: string;
    balance?: string;
    onKeyDown?: (e: any) => void;
    availableNodesAmount?: string;
}

const CustomInput: FC<OwnToProps> = ({
    value,
    setValue,
    label,
    icon,
    placeholder,
    type,
    currency,
    nodeValue,
    arrowBottomIcon,
    purchaseInput,
    min,
    max,
    half,
    purchaseValue,
    purchaseSetValue,
    limit,
    balance,
    availableNodesAmount,
    onKeyDown,
    token,
}) => {
    const [focus, setFocus] = useState<boolean>();
    const [activeBtn, setActiveBtn] = useState<number>(0);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        if (parseFloat(newValue) < 0) {
            return;
        }

        if (newValue.length <= 4) {
            let intValue = parseInt(newValue);
            if (limit !== undefined) {
                intValue = limit(intValue);
            }
            setValue(String(intValue));
        }
    };

    const btnValue = [
        { name: "min", value: min },
        { name: "half", value: half },
        { name: "max", value: max },
    ];

    return (
        <div
            className={styles.selectContainer}
            style={focus ? { borderColor: "#009B5B" } : {}}
        >
            <label>I Want to purchase</label>
            <div className={styles.customInputWrapper}>
                <div className={styles.customInputWrapperItem}>
                    <img width="24px" src={nodeIcon} />
                    <div>
                        <input
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            type={type}
                            value={value}
                            maxLength={2}
                            onChange={handleChange}
                            placeholder={placeholder || "0.00"}
                        />
                        {currency && (
                            <span style={value ? { color: "#212323" } : {}}>
                                xETH
                            </span>
                        )}
                    </div>
                </div>
                <div className={styles.btnContainer}>
                    {btnValue.map(({ name, value }, index) => (
                        <button
                            style={
                                activeBtn && activeBtn === index + 1
                                    ? {
                                          backgroundColor: "#FFFFFF",
                                          color: "#212323",
                                      }
                                    : { backgroundColor: "#EFF1F2" }
                            }
                            key={index}
                            onClick={() => {
                                setValue(value as string);
                                setActiveBtn(index + 1);
                            }}
                        >
                            {name}
                        </button>
                    ))}
                </div>
            </div>
            {value && (
                <div className={styles.conversion}>
                    {arrowBottomIcon && (
                        <div className={styles.arrowBottom}>
                            <img src={arrowBottom} alt="arrowBottom" />
                        </div>
                    )}
                    {purchaseInput && (
                        <AddressInput
                            value={purchaseValue}
                            setValue={purchaseSetValue}
                            label={label}
                        />
                    )}
                    <>
                        {purchaseInput ? (
                            <div className={styles.purchasesInput}>
                                <p>
                                    <img src={not} alt="not" />
                                    [Network information text]:
                                </p>
                                <p>
                                    <img src={polygon} alt="polygon" />
                                    Polygon
                                </p>
                            </div>
                        ) : (
                            <p>
                                Your Balance:{" "}
                                <span>
                                    <img
                                        width="24px"
                                        src={icon}
                                        alt="token icon"
                                    />{" "}
                                    {Number(balance)?.toFixed(2) ?? "-"}{" "}
                                    {token ? token : "ETH"}
                                </span>
                            </p>
                        )}
                    </>
                </div>
            )}
        </div>
    );
};

export default CustomInput;
