import React, { FC, Fragment, useEffect, useState } from "react";
import styles from "./tiers.module.scss";
import {
    GoBackButton,
    Message,
    SkeletonTier,
    TierItem,
} from "../../components";
import { useAuth } from "../../context/auth.context";
import infoIcon from "./../../assets/images/icon/info.svg";
import { TiersService } from "../../services/tiers.service";
import { NodeSale__factory } from "../../typechain-types";
import { getRegister } from "../../utils";
import { WS } from "../../services/wallet.service";

const TiersPage: FC = () => {
    const { saveTier, isAuth } = useAuth();

    const [isChainError, setIsChainError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [info, setInfo] = useState<boolean>(true);
    const [tiers, setTiers] = useState<any>([]);

    useEffect(() => {
        const getTiers = async (ret: number) => {
            try {
                setIsLoading(true);

                const tiersService = new TiersService();
                const tiers: Array<any> = await tiersService.getAllTiers();

                const contractRegister = getRegister();

                try {
                    const nodeSaleAddress =
                        await contractRegister.contractByName("NodeSale");

                    const nodeSale = NodeSale__factory.connect(
                        nodeSaleAddress,
                        contractRegister.runner
                    );
                    const expandedTiers = await Promise.all(
                        tiers.map((t) => {
                            return nodeSale
                                .getTierSoldAmount(Number(t.id))
                                .then((amount) => {
                                    return {
                                        ...t,
                                        current_bought: Number(amount),
                                    };
                                });
                        })
                    );
                    setTiers(expandedTiers);
                } catch {
                    await new Promise((f) => setTimeout(f, 500));
                    ret++;
                    if (ret < 5) {
                        getTiers(ret);
                    }
                }
            } catch (err: any) {
                throw new Error(`Error during getting tiers`, err);
            } finally {
                setIsLoading(false);
            }
        };
        getTiers(0);
    }, []);

    return (
        <div className={styles.tiersPage}>
            <GoBackButton />
            <div className={styles.tiersPageContainer}>
                <h1>Tiers</h1>
                {isLoading ? (
                    <div className={styles.tiersContainer}>
                        {Array.from({ length: 12 }).map((_, index) => (
                            <SkeletonTier key={index} />
                        ))}
                    </div>
                ) : (
                    <Fragment>
                        {isChainError && (
                            <div className={styles.errorContainer}>
                                <Message
                                    backgroundColor="#FFF"
                                    color="#212323"
                                    icon={infoIcon}
                                    callback={() => setIsChainError(false)}
                                    message="Please change the network in your browser extension."
                                />
                            </div>
                        )}
                        {isError ||
                            (info && (
                                <div className={styles.errorContainer}>
                                    <Message
                                        backgroundColor="#FFF"
                                        color="#212323"
                                        icon={info && infoIcon}
                                        callback={
                                            info
                                                ? () => setInfo(false)
                                                : () => setIsError(false)
                                        }
                                        message={
                                            info
                                                ? "Please check the timeline periodically for any update. The distribution dates here are provided indicatively for your convenience and are subject to change."
                                                : "Failed to load tiers. Please refresh the page or try again later."
                                        }
                                    />
                                </div>
                            ))}
                        <div className={styles.tiersContainer}>
                            {tiers.map((el: any, index: number) => (
                                <div key={index} onClick={() => saveTier(el)}>
                                    <TierItem
                                        start_sale={el.start_sale}
                                        end_sale={el.end_sale}
                                        price_native_token={
                                            el.price_native_token
                                        }
                                        limit_emission={el.limit_emission}
                                        sale_limit={el.current_bought}
                                        id={el.id}
                                        key={el.id}
                                        isConnected={isAuth}
                                    />
                                </div>
                            ))}
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export default TiersPage;
