import React, {FC} from 'react';
import logo from './../../assets/images/logo/wpay-logo.svg'
import connectWallet from './../../assets/images/icon/connect-wallet.svg';
import styles from './header.module.scss';
import {Link} from "react-router-dom";
import {useAuth} from "../../context/auth.context";
import {WalletAddressIcon} from "../index";

const Header: FC = () => {

    const {isAuth} = useAuth();

    return (
        <header className={styles.header}>
            <Link to="/">
                <img src={logo} alt="logo"/>
            </Link>
            {isAuth ? <WalletAddressIcon/> :
            <div className={styles.btn}>
                <Link style={{display: 'flex', alignItems: 'center'}} to="/connect-wallet">
                <img src={connectWallet} alt="connectWallet"/>
                    <span>Connect Wallet</span>
                    </Link>
            </div>}
        </header>
    );
};

export default Header;
