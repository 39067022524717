import React, {FC, useState} from 'react';
import styles from './address.input.module.scss';
import clearIcon from './../../assets/images/icon/clear-icon.svg';

interface OwnToProps {
    label?: string;
    value?: string;
    setValue?: any
}

const AddressInput: FC<OwnToProps> = ({label, value, setValue}) => {

    const [focus, setFocus] = useState<boolean>(false);

    const pasteText = () => {
        navigator.clipboard.readText()
            .then(text => {
                setValue(text)
            })
            .catch(err => {
                console.error('Failed to read clipboard contents: ', err);
            });
    }


    return (
        <div onFocus={() => setFocus(true)}
             onBlur={() => setFocus(false)}
             className={styles.inputContainer}>
            <label>{label}</label>
            <div className={styles.inputItem}>
                <input
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                    style={focus ? {borderColor: "#009B5B"} : {}}
                    type="text"
                />
                <button style={{cursor: "pointer"}} onClick={() => setValue('')} className={styles.clear}><img
                    src={clearIcon} alt="clear"/>
                </button>
                <button onClick={pasteText} style={focus ? {color: "#009B5B", cursor: "pointer"} : {}}>Paste</button>
            </div>
        </div>
    );
};

export default AddressInput;