import React from 'react';
import QRCode from 'qrcode.react';
import styles from './qr.code.module.scss';

interface Props {
    uuid: string;
}

const QRCodeComponent: React.FC<Props> = ({ uuid}) => {
    const baseURL = `${window.location.origin}/offchain`;
    const qrCodeData = `${baseURL}?uuid=${uuid}`;

    return (
        <div className={styles.qrCode}>
            <QRCode  bgColor="#EFF1F2" value={qrCodeData} />
        </div>
    );
};

export default QRCodeComponent;
