import { TokenService } from "./token.service";
import { AuthService } from "./auth.service";

class OffchainService {
    private AuthService;

    constructor() {
        this.AuthService = new AuthService();
    }

    async proceedClaim(
        uuid: string,
        user_address: string,
        password: string,
        ignoreError: boolean = false
    ) {
        const token = await this.AuthService.getAuthToken();
        let res = await fetch(
            `${process.env.REACT_APP_BASE_URL_NODE_SALE}/offchain/claim/`,
            {
                method: "POST",
                headers: {
                    bearer: token as string,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    uuid,
                    user_address,
                    password,
                }),
            }
        );
        if (!res.ok && !ignoreError) {
            throw new Error("Failed.");
        }
        return await res.json();
    }

    async getApproval(uuid: string) {
        const token = await this.AuthService.getAuthToken();

        let res = await fetch(
            `${process.env.REACT_APP_BASE_URL_NODE_SALE}/offchain/approval?id=${uuid}`,
            {
                headers: {
                    bearer: token as string,
                },
            }
        );

        if (!res.ok) {
            throw new Error("Failed.");
        }
        return await res.json();
    }

    async getApprovals() {
        const token = await this.AuthService.getAuthToken();
        let res = await fetch(
            `${process.env.REACT_APP_BASE_URL_NODE_SALE}/offchain/approvals`,
            {
                headers: {
                    bearer: token as string,
                },
            }
        );
        if (!res.ok) {
            throw new Error("Failed.");
        }
        return await res.json();
    }
}

export { OffchainService };
