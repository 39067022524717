import React, { FC, useState } from "react";
import styles from "./token.bridge.module.scss";
import {
  CustomButton,
  CustomInput,
  Message,
  GoBackButton,
  Popup,
  SubContainer,
} from "../../components";
import metamask from "../../assets/images/icon/metamask-icon.svg";
import activityIcon from "../../assets/images/icon/activity-icon.svg";
import arrow from "../../assets/images/icon/arrow.svg";
import { useAuth } from "../../context/auth.context";
import { Link, useNavigate } from "react-router-dom";
import arrowBottom from "./../../assets/images/icon/arrow-bottom.svg";
import polygon from "./../../assets/images/logo/polygon.svg";
import ethereum from "./../../assets/images/logo/etherium.svg";
import smallArrow from "./../../assets/images/icon/small-arrow.svg";
import CustomSelect, { OptionType } from "../../components/custom-select";
import errorSmallIcon from "./../../assets/images/icon/error-small-icon.svg";
import { useWindowSize } from "../../hooks/useWindowSize.hook";

const TokenBridgePage: FC = () => {
  const { address, logout } = useAuth();
  const navigate = useNavigate();
  const [value, setValue] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const { width } = useWindowSize();

  const [selectValues, setSelectValues] = useState({
    fromNetwork: {
      value: "ethereum",
      label: "Ethereum",
      subLabel: "Subtext",
      icon: ethereum,
    },
    fromToken: null,
    bridgeTo: {
      value: "polygon-zkevm",
      label: "Polygon zkEVM",
      subLabel: "Polygon",
      icon: polygon,
    },
    toToken: null,
    gasFee: {
      value: "0.00045",
      label: "0.00045 ETH",
      subLabel: "0.00045 ETH",
      icon: ethereum,
    },
  });

  const handleSelectChange = (name: string, value: OptionType | null) => {
    setSelectValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const confirmTransaction = () => {
    closePopup();
  };

  const optionsNetwork = [
    {
      value: "ethereum",
      label: "Ethereum",
      subLabel: "Ethereum",
      icon: ethereum,
    },
    {
      value: "polygon-zkevm",
      label: "Polygon zkEVM",
      subLabel: "Polygon",
      icon: polygon,
    },
  ];

  const optionsToken = [
    { value: "ethereum", label: "ETH", subLabel: "ETH", icon: ethereum },
    { value: "polygon", label: "ETH", subLabel: "ETH", icon: polygon },
  ];

  const disOptions = [
    {
      value: "0.00045",
      label: "0.00045 ETH",
      subLabel: "0.00045 ETH",
      icon: ethereum,
    },
  ];

  const disconnect = () => {
    logout();
    navigate("/");
  };

  return (
    <div className={styles.tokenBridgePage}>
      <GoBackButton />
      <div className={styles.tokenBridgeContainer}>
        <h1>Token Bridge</h1>
        <div className={styles.subContainer}>
          <SubContainer paddingBottom={16}>
            <div>
              <div className={styles.walletInfo}>
                <div className={styles.walletInfoItem}>
                  <img className={styles.polygon} src={polygon} alt="polygon" />
                  <img src={metamask} alt="metamask" />
                  <div className={styles.walletAddress}>
                    <p>My Wallet</p>
                    <h4>
                      {address?.slice(0, (width as number) > 567 ? 30 : 17)}...
                    </h4>
                  </div>
                </div>
                <button onClick={disconnect}>Disconnect</button>
              </div>
              <Link className={styles.btnContainerActivity} to="/activity">
                <div className={styles.btnDescription}>
                  <img src={activityIcon} alt="activity" />
                  <h4>Activity</h4>
                </div>
                <div className={styles.btnIcon}>
                  <img src={arrow} alt="arrow" />
                </div>
              </Link>
            </div>
          </SubContainer>
        </div>
        <div className={styles.subContainer}>
          <SubContainer>
            <>
              <div className={styles.bridgeForm}>
                <h3>
                  Select the token you wish to bridge and enter the amount
                </h3>
                <h4>
                  Ensure you have enough balance and be aware of the gas fees.
                </h4>
                <div className={styles.selectContainer}>
                  <div className={styles.selectContainerItem}>
                    <CustomSelect
                      value={selectValues.fromNetwork}
                      onChange={(value) =>
                        handleSelectChange("fromNetwork", value)
                      }
                      options={optionsNetwork}
                      label="From Network"
                    />
                  </div>
                  <div className={styles.selectContainerItem}>
                    <CustomSelect
                      value={selectValues.fromToken}
                      onChange={(value) =>
                        handleSelectChange("fromToken", value)
                      }
                      options={optionsToken}
                      label="From Token"
                    />
                  </div>
                </div>
                <div className={styles.arrowBottom}>
                  <img src={arrowBottom} alt="arrowBottom" />
                </div>
                <div className={styles.selectContainer}>
                  <div className={styles.selectContainerItem}>
                    <CustomSelect
                      value={selectValues.bridgeTo}
                      onChange={(value) =>
                        handleSelectChange("bridgeTo", value)
                      }
                      options={optionsNetwork}
                      isDisabled
                      label="Bridge To"
                    />
                  </div>
                  <div className={styles.selectContainerItem}>
                    <CustomSelect
                      value={selectValues.toToken}
                      onChange={(value) => handleSelectChange("toToken", value)}
                      options={optionsToken}
                      label="To Token"
                    />
                  </div>
                </div>
              </div>

              <div>
                <CustomInput
                  icon={polygon}
                  label="I Want to Bridge"
                  value={value}
                  type="number"
                  currency
                  nodeValue={0.9907}
                  setValue={setValue}
                />
              </div>
              <div className={styles.selectContainerItem}>
                <CustomSelect
                  options={disOptions}
                  isDisabled
                  value={selectValues.gasFee}
                  onChange={(value) => handleSelectChange("gasFee", value)}
                  label="Estimated Gas Fee"
                />
              </div>
              <div>
                {isError && (
                  <Message
                    fontSize={14}
                    icon={errorSmallIcon}
                    message="Insufficient balance for bridging. Please check your balance and try again."
                  />
                )}
              </div>
              <div className={styles.btnSubmitContainer}>
                <CustomButton
                  maxWidth={446}
                  text="Bridge Tokens"
                  backgroundColor={!value || isError ? "#DCDEDC" : "#009B5B"}
                  color={"#FFF"}
                  type="button"
                  onClick={openPopup}
                  isDisable={!value || isError}
                />
              </div>
            </>
          </SubContainer>
        </div>
      </div>
      <Popup
        onConfirmName="Token Bridge"
        isOpen={isPopupOpen}
        name="Confirm Bridge Transaction"
        onClose={closePopup}
        onConfirm={confirmTransaction}
        isWait={false} //TODO: add wait state according to tx wait
      >
        <div className={styles.popupValue}>
          <h4>
            You are about to bridge{" "}
            <strong>
              {+value}
              xETH
            </strong>{" "}
            from <strong>{selectValues?.fromNetwork?.label}</strong> to{" "}
            <strong>{selectValues.bridgeTo.label}</strong>. This transaction
            will be processed securely.
          </h4>
          <div className={styles.descriptionBlock}>
            <div className={styles.iconContainer}>
              <img
                className={styles.iconFrom}
                src={selectValues.fromNetwork.icon}
                alt={selectValues.fromNetwork.label}
              />
              <img src={smallArrow} alt="arrow" />
              <img
                className={styles.iconTo}
                src={selectValues.bridgeTo.icon}
                alt={selectValues.bridgeTo.label}
              />
            </div>
            <div className={styles.descriptionForm}>
              <h2>
                Bridge {+value} <span>xETH</span>
              </h2>
              <p>
                Estimated Gas Fee: <span>0.00045 ETH</span>
              </p>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default TokenBridgePage;
