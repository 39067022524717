import React, {FC, useEffect} from "react";
import {QRCodeComponent, SubContainer} from "../../components";
import styles from './qr.page.module.scss';
import {useLocation, useNavigate} from "react-router-dom";

const useQuery = () => new URLSearchParams(useLocation().search);

const QRPage: FC = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const uuid = query.get('uuid');

  useEffect(() => {
    if (!uuid) {
      navigate('/');
    }
  }, [uuid, navigate]);
  return (
    <div className={styles.qrPage}>
      <SubContainer>
        <div className={styles.qrCodeWrapper}>
          <QRCodeComponent uuid={uuid as string}/>
        </div>
      </SubContainer>
    </div>
  )
}

export default QRPage
