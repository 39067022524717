import React, { FC, useState, useEffect } from "react";
import styles from "./connect.wallet.module.scss";
import {
    Message,
    GoBackButton,
    WalletBtn,
    WalletConnectSuccess,
} from "../../components";
import metamask from "./../../assets/images/icon/metamask-icon.svg";
import walletConnect from "./../../assets/images/icon/wallet-connect-icon.svg";
import { WalletService, WS } from "../../services/wallet.service";
import { useAuth } from "../../context/auth.context";
import { AuthService } from "../../services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";

const ConnectWalletPage: FC = () => {
    const [error, setError] = useState<boolean>(false);
    const [connectedWithMetaMask, setConnectedWithMetaMask] =
        useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    const { isAuth, login, address: localAddress, provider } = useAuth();

    useEffect(() => {
        const initWalletService = async () => {
            try {
                WS.initExtension();
            } catch (error) {
                console.error(error);
                setError(true);
            }
        };

        initWalletService();
    }, [location]);

    const handleConnectMetaMask = async () => {
        if (!WS) return setError(true);
        if (!WS) return setError(true);
        try {
            const { account, signer } = await WS.connectWallet();

            setConnectedWithMetaMask(true);

            try {
                let auth = new AuthService();
                const signature = await WS.signMessage(signer);
                let response = await auth.signIn(account, signature);

                setError(false);
                if (response) login(response, account as string, "Metamask");
                if (response) login(response, account as string, "Metamask");
                if (location.state?.from?.includes("/offchain")) {
                    navigate(location.state?.from);
                }
            } catch (error) {
                console.error(error);
                setError(true);
            }
        } catch (error) {
            console.error(error);
            setError(true);
        }
    };

    const handleConnectWalletConnect = async () => {
        if (!WS) return setError(true);
        if (!WS) return setError(true);
        try {
            const res = await WS.connectWalletConnect();
            const signer = res?.signer ?? null;
            const account = res?.account ?? null;

            try {
                let auth = new AuthService();
                const signature = await WS.signMessage(signer);
                let response = await auth.signIn(account, signature);

                setError(false);
                if (response)
                    login(response, account as string, "WalletConnect");
                if (response)
                    login(response, account as string, "WalletConnect");
                if (location.state?.from?.includes("/offchain")) {
                    navigate(location.state?.from);
                }
            } catch (error) {
                console.error(error);
                setError(true);
            }
        } catch (error) {
            console.error(error);
            setError(true);
        }
    };

    const walletBtn = [
        {
            name: "Metamask",
            description: "Using QR Code or web extension",
            icon: metamask,
            callback: handleConnectMetaMask,
        },
        {
            name: "ConnectWallet",
            description: "Using QR Code or web extension",
            icon: walletConnect,
            callback: handleConnectWalletConnect,
        },
    ];

    if (isAuth) return <WalletConnectSuccess />;

    return (
        <div style={{ position: "relative" }}>
            <GoBackButton />
            <div className={styles.connectWallet}>
                <h1>Connect Wallet</h1>
                <h3>
                    Choose your preferred wallet provider to connect securely
                </h3>
                {error && (
                    <Message
                        callback={() => setError(false)}
                        name="Wallet connection failed."
                        message="Please check your wallet and try again"
                    />
                )}
                {walletBtn.map(
                    ({ name, description, icon, callback }, index) => (
                        <div key={index} className={styles.btnContainer}>
                            <WalletBtn
                                name={name}
                                description={description}
                                icon={icon}
                                callback={callback}
                            />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default ConnectWalletPage;
