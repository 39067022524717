import React, {FC, useState} from 'react';
import styles from './password.input.module.scss';
import passwordIcon from './../../assets/images/icon/password.svg';
import closeIcon from './../../assets/images/icon/icon-close.svg';
import openIcon from './../../assets/images/icon/icon-open.svg';

interface OwnToProps {
    value: string
    setValue: (p: string) => void
    label?: string
    placeholder?: string
    isDisabled?: boolean
}

const PasswordInput: FC<OwnToProps> = ({value, setValue, label,placeholder, isDisabled}) => {
    const [show, setShow] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);

    return (
        <>
            <label>
                {label}
            </label>
            <div
                style={isDisabled ? {backgroundColor: "#EFF1F266"} : (focus ? {borderColor: '#009B5B'} : {borderColor: "#DCE0DC"})}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                className={styles.inputContainer}
            >
                <img className={styles.passwordIcon} src={passwordIcon} alt="password"/>
                <input
                    disabled={isDisabled}
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                    type={show ? "text" : "password"}
                    placeholder={placeholder}
                />
                <img className={styles.eyeIcon} onClick={() => setShow(!show)} src={show ? openIcon : closeIcon} alt="icon"/>
            </div>
        </>
    );
};

export default PasswordInput;