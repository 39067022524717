import React, {FC} from 'react';
import styles from './wallet.btn.module.scss';
import arrowWhite from './../../assets/images/icon/arrow-white.svg';

interface OwnToProps{
    name: string;
    description: string;
    icon: string
    callback: () => void
}

const WalletBtn:FC<OwnToProps> = ({name, description, icon, callback}) => {
    return (
        <div className={styles.btnContainer}>
            <div className={styles.descriptionContainer}>
                <img src={icon}/>
                <div className={styles.descriptionInfo}>
                    <h4>{name}</h4>
                    <p>{description}</p>
                </div>
            </div>
            <div className={styles.btnContainerItem}>
                <button onClick={callback}>
                    <span>Connect</span>
                    <img src={arrowWhite} alt="arrow"/>
                </button>
            </div>
        </div>
    );
};

export default WalletBtn;