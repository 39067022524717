export class PurchasesService {
    public async getPurchaseOwnerAddress(address: string, id?: string) {
        const url = `${
            process.env.REACT_APP_BASE_URL_NODE_SALE
        }/purchases/by_purchase_owner/${address}?limit=100000${
            id ? `&tier_id=${id}` : ""
        }`;
        let res = await fetch(url);
        if (!res.ok) {
            throw new Error("Failed.");
        }
        const r = await res.json();
        return r as Purchases;
    }
}

export type Purchases = {
    purchases: Purchase[];
};

export type Purchase = {
    purchase_tx_hash: string;
    id: string;
    uuid: string;
    owner: string;
    buy_ts: number; // DATE
    is_public_sale: boolean;
    amount_payed: number;
    currency: Currency;
    tier: Tier;
    erc721_token: Erc721;
    priceProof: PriceProof;
};

type Currency = {
    address: string;
    symbol: string;
    name: string;
    decimal: number;
};

type Tier = {
    id: string;
    start_sale: string;
    end_sale: string;
    start_sale_ts: number;
    end_sale_ts: number;
    price_native_token: string;
    limit_emission: string;
    sale_limit: string;
    instant_mint: boolean;
    public_sale_available: boolean;
};

type Erc721 = {
    id: string;
    owner: string;
    mint_tx_hash: string;
};

type PriceProof = {
    token_address: string;
    rate: number;
    price_signature: string;
    signer: string;
};
