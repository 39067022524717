import React, {createContext, useState, useContext, useEffect, FC} from 'react';

export const PROVIDER = 'PROVIDER';

interface AuthContextType {
    isAuth: boolean;
    authToken: string | null;
    address: string | null;
    login: (token: string, address: string, provider: "Metamask" | "WalletConnect") => void;
    logout: () => void;
    loading: boolean;
    saveTier: (p: any) => void;
    savePurchase: (p: any) => void;
    provider: "Metamask" | "WalletConnect" | null;
    tier: any;
    purchase: any
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface OwnToProps {
    children: JSX.Element
}

export const AuthProvider: FC<OwnToProps> = ({children}) => {
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [address, setAddress] = useState<string | null>(null);
    const [authToken, setAuthToken] = useState<string | null>(null);
    const [tier, setTier] = useState(null);
    const [purchase, setPurchase] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [provider, setProvider] = useState<"Metamask" | "WalletConnect" | null>(null);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const address = localStorage.getItem('address');
        const provider = localStorage.getItem(PROVIDER);
        if (!!token && !!address && !!provider) {
            setIsAuth(true);
            setAuthToken(token);
            setAddress(address);
            // @ts-ignore
            setProvider(provider);
        }
        setLoading(false);
    }, []);

    const login = (token: string, address: string, provider: "Metamask" | "WalletConnect" | null) => {
        setIsAuth(true);
        setAddress(address);
        setAuthToken(token);
        setProvider(provider)
        localStorage.setItem('authToken', token);
        localStorage.setItem('address', address);
        localStorage.setItem(PROVIDER, provider as string);
    };

    const logout = () => {



        setIsAuth(false);
        setAddress(null);
        setAuthToken(null);
        setProvider(null);
        localStorage.removeItem('authToken');
        localStorage.removeItem('address');
        localStorage.removeItem(PROVIDER)
    };

    const saveTier = (tier: any) => {
        setTier(tier)
    }
    const savePurchase = (purchase: any) => {
        setPurchase(purchase)
    }

    return (
        <AuthContext.Provider value={{isAuth, authToken, login, logout, address, loading, saveTier, tier, provider, savePurchase, purchase}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
