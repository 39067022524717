import { AuthService } from "./auth.service";

export class PrivateSaleService {
    private AuthService = new AuthService();

    public async getPermission(tierId: number, amount: number) {
        const token = await this.AuthService.getAuthToken();
        const res = await fetch(
            `${process.env.REACT_APP_BASE_URL_NODE_SALE}/private_sale/permission?tier_id=${tierId}&amount=${amount}`,
            {
                headers: {
                    bearer: token as string,
                },
            }
        );

        const r = await res.json();
        if (!res.ok) {
            const desc = r.errors[0].description as string;
            if (desc.includes("previous signature not used"))
                throw new Error(PrivateSaleError.NotUsedSignature);
        }
        r.permission.created_at = new Date(r.permission.created_at);
        r.permission.updated_at = new Date(r.permission.updated_at);
        return r.signature.sig as string;
    }

    public async getUnusedSignature(tierId: number, amount: number) {
        const token = await this.AuthService.getAuthToken();
        const res = await fetch(
            `${process.env.REACT_APP_BASE_URL_NODE_SALE}/private_sale/last_signature?tier_id=${tierId}&amount=${amount}`,
            {
                headers: {
                    bearer: token as string,
                },
            }
        );
        const r = await res.json();
        if (!res.ok) {
            const desc = r.errors[0].description as string;
            if (desc.includes("last signature cant be recalled"))
                throw new Error(PrivateSaleError.NotUsedSignature);
        }
        return r.sig as string;
    }

    public async getPrivateSalePermissions() {
        const token = await this.AuthService.getAuthToken();
        const res = await fetch(
            `${process.env.REACT_APP_BASE_URL_NODE_SALE}/private_sale/permissions`,
            {
                headers: {
                    bearer: token as string,
                },
            }
        );

        if (!res.ok) {
            throw new Error("Failed.");
        }
        const data = await res.json();
        return data as PrivateSalePermissions;
    }
}

type PrivateSalePermission = {
    permission: Permission;
    signature: Signature;
};

type PrivateSalePermissions = {
    Permissions: Permission[];
};

export type Permission = {
    id: string;
    created_at: Date;
    updated_at: Date;
    user_address: string;
    tier_id: string;
    amount_left: number;
    last_nonce_used: number;
    last_signature: string;
};

type Signature = {
    sig: string;
};

// Error

export enum PrivateSaleError {
    NotUsedSignature = "NotUsedSignature",
    IncorrectAmount = "IncorrectAmount",
}
