import React, { FC, useEffect, useState } from "react";
import styles from "./private.sale.page.module.scss";
import {
    GoBackButton,
    SkeletonTier,
    SubContainer,
    TierItemPrivate,
    WalletInfo,
} from "../../components";
import { useAuth } from "../../context/auth.context";
import { Tier, TiersService } from "../../services/tiers.service";
import {
    Permission,
    PrivateSaleService,
} from "../../services/privateSale.service";
import { getRegister } from "../../utils";
import { NodeSale__factory } from "../../typechain-types";

const PrivateSalePage: FC = () => {
    const { saveTier } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [tiers, setTiers] = useState<any>([]);

    useEffect(() => {
        const getTiers = async (ret: number) => {
            setIsLoading(true);
            const tiersService = new TiersService();
            const privateSaleService = new PrivateSaleService();
            let allTiers = await tiersService.getAllTiers();
            let { Permissions } =
                await privateSaleService.getPrivateSalePermissions();
            const contractRegister = getRegister();
            try {
                const nodeSaleAddress = await contractRegister.contractByName(
                    "NodeSale"
                );

                const nodeSale = NodeSale__factory.connect(
                    nodeSaleAddress,
                    contractRegister.runner
                );
                const updatedTiers = await Promise.all(
                    allTiers.map((tier: Tier) => {
                        const permission = Permissions.find(
                            (permission: Permission) =>
                                permission.tier_id === tier.id
                        );
                        if (permission) {
                            return nodeSale
                                .getTierSoldAmount(Number(tier.id))
                                .then((amount) => {
                                    return {
                                        ...tier,
                                        left: permission.amount_left,
                                        current_bought: Number(amount),
                                    };
                                });
                        }
                        return tier;
                    })
                ).then((tiers) =>
                    tiers.filter((tier: Tier) => tier.hasOwnProperty("left"))
                );
                setTiers(updatedTiers);
            } catch (e) {
                await new Promise((f) => setTimeout(f, 500));
                ret++;
                if (ret < 5) {
                    getTiers(ret);
                }
            }

            if (tiers) {
                setTimeout(() => {
                    setIsLoading(false);
                }, 2000);
            }
        };
        getTiers(0);
    }, []);

    return (
        <div className={styles.privateSale}>
            <GoBackButton />
            <div className={styles.privateSaleContainer}>
                <h1 style={{ paddingTop: 10 }}>Whitelist Sale</h1>
                <h3>
                    You are participating in the{" "}
                    <span>Exclusive Whitelist Sale</span>. We appreciate your
                    commitment to this early opportunity. Thank you for your
                    support and trust in our vision.
                </h3>
                <h5>
                    Enjoy the special privileges and benefits of being among the
                    first to join!
                </h5>
                <WalletInfo />
                <div className={styles.tiersContainer}>
                    {tiers.length > 0
                        ? tiers.map((el: any) => (
                              <div onClick={() => saveTier(el)}>
                                  {!isLoading ? (
                                      <TierItemPrivate
                                          start_sale={el.start_sale}
                                          end_sale={el.end_sale}
                                          price_native_token={
                                              el.price_native_token
                                          }
                                          limit_emission={el.sale_limit}
                                          current_bought={el.current_bought}
                                          total_nodes={el.limit_emission}
                                          sale_limit={el.left}
                                          id={el.id}
                                          key={el.id}
                                      />
                                  ) : (
                                      <SkeletonTier />
                                  )}
                              </div>
                          ))
                        : "Loading..."}
                </div>
            </div>
        </div>
    );
};

export default PrivateSalePage;
