import React from "react";
import "./App.scss";
import { RouterProvider } from "react-router-dom";
import { publicRouter } from "./routes/public";
import { privateRouter } from "./routes/private";
import { useAuth } from "./context/auth.context";
import { Helmet } from "react-helmet";

function App() {
  const { isAuth, loading } = useAuth();

  if (loading) return <div>Loading</div>;

  return (
    <div className="App">
      <Helmet>
        <title>Wirex Pay Node Sale: Join the Decentralized Future</title>
        <meta
          name="description"
          content="Participate in the Wirex Pay Node Sale and become a part of the decentralized payment revolution. Secure your WPAY rewards and contribute to the network. Learn more and join now!"
        />

        <link
          rel="icon"
          sizes="192x192"
          href="https://static.wixstatic.com/media/f627bc_9b94340863c94f36b1083102c464b7f6%7Emv2.png/v1/fill/w_192%2Ch_192%2Clg_1%2Cusm_0.66_1.00_0.01/f627bc_9b94340863c94f36b1083102c464b7f6%7Emv2.png"
          type="image/png"
        />

        <link
          rel="shortcut icon"
          href="https://static.wixstatic.com/media/f627bc_9b94340863c94f36b1083102c464b7f6%7Emv2.png/v1/fill/w_32%2Ch_32%2Clg_1%2Cusm_0.66_1.00_0.01/f627bc_9b94340863c94f36b1083102c464b7f6%7Emv2.png"
          type="image/png"
        />

        <link
          rel="apple-touch-icon"
          href="https://static.wixstatic.com/media/f627bc_9b94340863c94f36b1083102c464b7f6%7Emv2.png/v1/fill/w_180%2Ch_180%2Clg_1%2Cusm_0.66_1.00_0.01/f627bc_9b94340863c94f36b1083102c464b7f6%7Emv2.png"
          type="image/png"
        />

        <meta
          property="og:image"
          content="https://static.wixstatic.com/media/f627bc_b78699cc279e47f6bd23606bef70230e%7Emv2.png/v1/fit/w_2500,h_1330,al_c/f627bc_b78699cc279e47f6bd23606bef70230e%7Emv2.png"
        />

        <meta property="og:image:width" content="2500" />

        <meta property="og:image:height" content="1330" />

        <meta
          property="og:url"
          content="https://www.wirexpaychain.com/faq"
        ></meta>
      </Helmet>
      <RouterProvider router={isAuth ? privateRouter : publicRouter} />
    </div>
  );
}

export default App;
