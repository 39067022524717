import React, { FC, useEffect, useState, useCallback, Fragment } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./one.tier.page.module.scss";
import {
    CustomButton,
    CustomInput,
    GoBackButton,
    Message,
    Popup,
    ProgressBar,
    SubContainer,
} from "../../components";
import { useAuth } from "../../context/auth.context";
import infoIcon from "./../../assets/images/icon/info.svg";
import tierIcon from "./../../assets/images/icon/tier-icon.svg";
import nodeIcon from "./../../assets/images/icon/node-icon.svg";
import polygon from "./../../assets/images/logo/polygon.svg";

import nodes from "./../../assets/images/icon/nodes.svg";
import errorIcon from "./../../assets/images/icon/error-small-icon.svg";
import success from "./../../assets/images/icon/success.svg";
import WalletInfo from "../../components/wallet-info";
import { Tier, TiersService } from "../../services/tiers.service";
import { useNavigate, useParams } from "react-router-dom";

import Skeleton from "react-loading-skeleton";

import eth from "./../../assets/images/logo/etherium.svg";
import weth from "./../../assets/images/logo/etherium.svg";
import usdt from "./../../assets/images/logo/USDT.svg";
import usdc from "./../../assets/images/logo/USDC.svg";
import dai from "./../../assets/images/logo/DAI.svg";
import { ethers } from "ethers";
import { WS } from "../../services/wallet.service";
import { getRegister } from "../../utils";
import {
    ContractRegister,
    ERC20Token__factory,
    INodePurchase,
    INodeTiers,
    ITokenRegister,
    NodeSale__factory,
    NodeTiers__factory,
    TokenRegister__factory,
} from "../../typechain-types";
import CustomSelect from "../../components/custom-select";
import { PriceService } from "../../services/price.service";
import {
    PrivateSaleError,
    PrivateSaleService,
} from "../../services/privateSale.service";

const getColor = (percentage: number) => {
    if (percentage < 30) return "#FF3B30";
    if (percentage < 50) return "#FF9922";
    return "#009B5B";
};

const ETH_DECIMALS = 10 ** 18;
export const formatToEth = (value: number) => (value / ETH_DECIMALS).toFixed(2);

const tokenIcons = {
    eth,
    usdt,
    usdc,
    dai,
    weth,
};

type Tokens = {
    [symbol: string]: Token;
};

type Token = {
    address: string;
    symbol: string;
    decimals: number;
    rate: number;
    tierPrice: string;
};

type SelectOptionToken = {
    value: "weth" | "usdt" | "usdc" | "dai" | string;
    label: string;
    subLabel: string;
    icon: string;
    tokenAddress: string;
    tokenDecimals: number;
};

type UserBalances = {
    [symbol: string]: string;
};

const getUserBalances = async (
    provider: ethers.BrowserProvider,
    signer: ethers.JsonRpcSigner,
    tokens: ITokenRegister.TokenStructOutput[]
) => {
    const balances: UserBalances = {};
    for (const token of tokens) {
        const erc20contract = ERC20Token__factory.connect(
            token.tokenAddress,
            provider
        );
        const balance = await erc20contract.balanceOf(signer);
        balances[token.symbol] = ethers.formatUnits(balance, token.decimals);
    }
    return balances;
};

const getAvailableTokens = async (
    tokens: ITokenRegister.TokenStructOutput[],
    tier: INodeTiers.TierStructOutput
) => {
    const p = new PriceService();
    const t: Tokens = {};
    for (const token of tokens) {
        const proof = await p.getPriceProof(token.tokenAddress);
        const tierPrice =
            (tier.priceNativeToken * 10n ** token.decimals) /
            BigInt(proof.rate);
        t[token.symbol] = {
            address: token.tokenAddress,
            symbol: token.symbol,
            decimals: Number(token.decimals),
            rate: proof.rate,
            tierPrice: ethers.formatUnits(tierPrice, token.decimals),
        };
    }
    return t;
};

const OneTierPage: FC = () => {
    const { address, logout, isAuth } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [purchaseIsLoading, setPurchaseIsLoading] = useState<boolean>(false);
    const [isWait, setIsWait] = useState<boolean>(false);
    const [txHash, setTxHash] = useState<string>("");
    const [tier, setTier] = useState<any>();
    const [amount, setAmount] = useState<number>(); // amount of tiers to by
    const [isError, setIsError] = useState<boolean>(false);
    const [isWalletError, setIsWalletError] = useState<boolean>(false);
    const [isPermissionError, setIsPermissionError] = useState<boolean>(false);
    const [isModal, setIsModal] = useState<boolean>(false);
    const [isAvailable, setIsAvailable] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [optionsTokens, setOptionsTokens] = useState<SelectOptionToken[]>([]);
    const [selectedToken, setSelectedToken] = useState<SelectOptionToken>(
        optionsTokens?.[0]
    );
    const [availableTokens, setAvailableTokens] = useState<Tokens>();
    const [userBalances, setUserBalances] = useState<UserBalances>();

    useEffect(() => {
        const fetchPermissions = async () => {
            const privateSaleService = new PrivateSaleService();
            let { Permissions } =
                await privateSaleService.getPrivateSalePermissions();
            return Permissions;
        };
        const fetchTier = async () => {
            let _signer;
            let _account;
            try {
                const walletService = WS;
                const { account, signer } = await walletService.connectWallet();
                _signer = signer;
                _account = account;
            } catch (err) {
                setIsError(true);
                setIsWalletError(true);
                return;
            }

            let _permissions;
            let _tier: Tier;
            try {
                const tiersService = new TiersService();
                const [tier, permissons] = await Promise.all([
                    tiersService.getTierById(id as string),
                    fetchPermissions(),
                ]);
                _permissions = permissons;
                _tier = tier;
            } catch (err) {
                setIsError(true);
                setIsPermissionError(true);
                return;
            }

            const permitedTier = _permissions.find(
                (_permissions) => _permissions?.tier_id === _tier?.id
            );

            if (!_signer) {
                console.error("No signer or account found");
                setIsError(true);
                return;
            }

            const registerAddress =
                process.env.REACT_APP_CONTRACT_REGISTER_ADDRESS!;
            if (!registerAddress) {
                console.error("Register address wasn't set");
                setIsError(true);
                return;
            }

            const contractRegister = getRegister(
                _signer
            ) as unknown as ContractRegister;

            const nodeSaleAddress = await contractRegister.contractByName(
                "NodeSale"
            );

            const nodeSale = NodeSale__factory.connect(
                nodeSaleAddress,
                _signer
            );

            const sold_amount = await nodeSale.getTierSoldAmount(id as string);
            const isPublicSale = await nodeSale.getPublicSaleStatus(
                id as string
            );

            const current_bought = Number(BigInt(sold_amount));
            const privateLeft = permitedTier?.amount_left;
            const expandedTier = {
                ..._tier,
                current_bought,
                privateLeft,
                isPublicSale,
            };
            setIsAvailable(
                permitedTier ? permitedTier.amount_left > 0 : isPublicSale
            );
            setTier(expandedTier);
        };
        fetchTier();
    }, [id]);

    useEffect(() => {
        if (id) getPriceAndBalance(Number(id));
    }, [id]);

    useEffect(() => {
        // Populate select
        if (availableTokens && userBalances) {
            const options: SelectOptionToken[] = Object.entries(
                availableTokens
            ).map(([symbol, token]) => {
                const label = Number(token.tierPrice).toFixed(2);
                const fixedSymbol = token.symbol.toUpperCase();
                const subLabel =
                    fixedSymbol === "USDC" ? "USDC.e" : fixedSymbol;
                const icon =
                    tokenIcons[
                        symbol.toLowerCase() as keyof typeof tokenIcons
                    ] || "";
                const tokenAddress = token.address;
                const tokenDecimals = token.decimals;

                return {
                    value: symbol.toLowerCase(),
                    label,
                    subLabel,
                    icon,
                    tokenAddress,
                    tokenDecimals,
                };
            });

            setOptionsTokens(options);
            setSelectedToken(options[0]);
        }
    }, [availableTokens, userBalances]);

    const progress = useCallback(() => {
        if (tier?.privateLeft) {
            return ((+tier?.privateLeft / +tier.sale_limit) * 100).toFixed(2);
        }
        if (tier?.current_bought && tier?.limit_emission) {
            return (
                (+tier.current_bought / +tier.limit_emission) *
                100
            ).toFixed(2);
        }
        return "0.00";
    }, [tier]);

    const getPriceAndBalance = async (tierId: number) => {
        setIsLoading(true);
        try {
            let _account;
            let _signer: ethers.JsonRpcSigner;
            let _ethersProvider: ethers.BrowserProvider;
            try {
                const walletService = WS;
                const { account, signer, ethersProvider } =
                    await walletService.connectWallet();
                _account = account;
                _signer = signer;
                _ethersProvider = ethersProvider;
            } catch (err) {
                setIsError(true);
                setIsWalletError(true);
                return;
            }

            if (!_signer) {
                console.error("No signer or account found");
                setIsError(true);
                return;
            }

            const registerAddress =
                process.env.REACT_APP_CONTRACT_REGISTER_ADDRESS!;
            if (!registerAddress) {
                console.error("Register address wasn't set");
                setIsError(true);
                return;
            }

            const contractRegister = getRegister(
                _signer
            ) as unknown as ContractRegister;

            const tokenRegisterAddress = await contractRegister.contractByName(
                "TokenRegister"
            );

            const tiersAddress = await contractRegister.contractByName(
                "NodeTiers"
            );

            const tokenRegister = TokenRegister__factory.connect(
                tokenRegisterAddress,
                _ethersProvider
            );
            const tiersContract = NodeTiers__factory.connect(
                tiersAddress,
                _ethersProvider
            );

            try {
                const [userBalances, availableTokens] = await Promise.all([
                    tokenRegister.getAllTokens(),
                    tiersContract.getTier(tierId),
                ]).then(([tokens, tier]) =>
                    Promise.all([
                        getUserBalances(_ethersProvider, _signer, tokens),
                        getAvailableTokens(tokens, tier),
                    ])
                );

                setAvailableTokens(availableTokens);
                setUserBalances(userBalances);

                return { availableTokens, userBalances };
            } catch (err) {
                setIsError(true);
                setIsWalletError(true);
                return;
            }
        } catch (err) {
            setIsError(true);
            throw new Error("Error during getPriceAndBalance");
        } finally {
            setIsLoading(false);
        }
    };

    const approveERC20 = async (
        signer: ethers.JsonRpcSigner,
        tokenAddress: string,
        nodeSaleAddress: string,
        amount: bigint
    ) => {
        const erc20contract = ERC20Token__factory.connect(tokenAddress, signer);
        const currentAllowance = await erc20contract.allowance(
            signer,
            nodeSaleAddress
        );
        if (amount > currentAllowance) {
            try {
                setIsWait(true);
                const tx = await erc20contract.approve(nodeSaleAddress, amount);
                await tx.wait();
                setIsWait(false);
            } catch (e) {
                throw new Error(`Approve transaction err: ${e}`);
            }
        }
    };

    const submit = async () => {
        try {
            setPurchaseIsLoading(true);
            if (!isAuth || !amount) {
                setIsError(true);
                return;
            }
            const walletService = WS;
            const { account, signer, ethersProvider } =
                await walletService.connectWallet();

            console.log(signer);
            if (!signer) {
                console.error("No signer or account found");
                setIsError(true);
                return;
            }
            // console.log("=========");

            const contractRegister = getRegister(
                signer
            ) as unknown as ContractRegister;

            const nodeSaleAddress = await contractRegister.contractByName(
                "NodeSale"
            );

            // console.log(nodeSaleAddress);
            // console.log("=========");

            const nodeSaleContract = NodeSale__factory.connect(
                nodeSaleAddress,
                signer
            );

            const isPublicSale = await nodeSaleContract.getPublicSaleStatus(
                BigInt(tier.id)
            );
            const privateSaleService = new PrivateSaleService();
            const priceService = new PriceService();

            const proof = await priceService.getPriceProof(
                selectedToken.tokenAddress
            );

            // console.log(proof.token_address);
            // console.log("=========");

            const priceProof: INodePurchase.PriceProofStruct = {
                tokenAddress: proof.token_address,
                rate: BigInt(proof.rate),
                timestamp: proof.valid_til,
                priceSignature: proof.sig,
                signer: "0x23222802D20BcDde6Ae0766c212906521D1Dd52C",
            };

            const tierPrice =
                (BigInt(String(tier.price_native_token)) *
                    10n ** BigInt(selectedToken.tokenDecimals)) /
                BigInt(proof.rate);
            const totalPrice = tierPrice * BigInt(amount);

            // console.log(totalPrice);
            // console.log("=========");
            //
            // console.log(tierPrice);
            // console.log("=========");
            //
            // console.log(nodeSaleAddress);
            // console.log("=========");

            await approveERC20(
                signer,
                selectedToken.tokenAddress,
                nodeSaleAddress,
                totalPrice
            );

            if (isPublicSale) {
                setIsWait(true);
                await nodeSaleContract
                    .buyTierPublicERC20(BigInt(tier.id), amount, priceProof)
                    .then((tx) =>
                        tx.wait().then((r) => setTxHash(r ? r.hash : ""))
                    )
                    .catch((err) => console.error(err));
            } else {
                let permission: string;
                try {
                    permission = await privateSaleService.getPermission(
                        tier.id,
                        amount
                    );
                    // console.log(permission)
                    // console.log("----------")
                } catch (e) {
                    const err = e as Error;
                    if (err.message === PrivateSaleError.NotUsedSignature) {
                        try {
                            permission =
                                await privateSaleService.getUnusedSignature(
                                    tier.id,
                                    amount
                                );
                        } catch (e) {
                            const err = e as Error;
                            throw new Error(
                                "Buy tier with unused sig err: ",
                                err
                            );
                        }
                    } else {
                        throw new Error("Buy tier with sig err");
                    }
                }

                // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@");
                // console.log(tier.id);
                // console.log(amount);
                // console.log(priceProof);
                // console.log(permission);
                // console.log("@@@@@@@@@@@@@@@@@@@@@@@");

                setIsWait(true);
                await nodeSaleContract
                    .buyTierPrivateERC20(
                        BigInt(tier.id),
                        amount,
                        priceProof,
                        permission
                    )
                    .then((tx) =>
                        tx.wait().then((r) => setTxHash(r ? r.hash : ""))
                    );
            }
            setIsWait(false);
            setIsSuccess(true);
        } catch (error) {
            console.error("Transaction Error:", error);
        } finally {
            setPurchaseIsLoading(false);
            setIsModal(false);
        }
    };

    return (
        <div className={styles.tierPage}>
            <GoBackButton />
            <div className={styles.tierPageContainer}>
                <h1>Purchase Node</h1>
                {isLoading ? (
                    <div style={{ maxWidth: "678px" }}>
                        <Skeleton
                            style={{ marginBottom: "1.5rem" }}
                            height={113}
                            width={"100%"}
                            borderRadius={20}
                        />
                        <Skeleton
                            height={855}
                            width={"100%"}
                            borderRadius={20}
                        />
                    </div>
                ) : (
                    <Fragment>
                        {isWalletError && (
                            <div
                                className={styles.errorContainer}
                                style={{ marginBottom: 34 }}
                            >
                                <Message
                                    backgroundColor="#FFF"
                                    color="#212323"
                                    icon={infoIcon}
                                    callback={() => setIsWalletError(false)}
                                    message="Please connect with your wallet and chose the correct network to participate in the sale."
                                />
                            </div>
                        )}
                        {isPermissionError && (
                            <div
                                className={styles.errorContainer}
                                style={{ marginBottom: 34 }}
                            >
                                <Message
                                    backgroundColor="#FFF"
                                    color="#212323"
                                    icon={infoIcon}
                                    callback={() => setIsPermissionError(false)}
                                    message="You lack permissions for this action."
                                />
                            </div>
                        )}
                        <WalletInfo />
                        {!isError && (
                            <SubContainer>
                                <TierInfo
                                    id={id}
                                    tier={tier}
                                    amount={amount}
                                    setAmount={setAmount}
                                    isSuccess={isSuccess}
                                    isAvailable={isAvailable}
                                    selectedToken={selectedToken}
                                    setSelectedToken={setSelectedToken}
                                    optionsTokens={optionsTokens}
                                    userBalances={userBalances}
                                    isError={isError}
                                    isLoading={purchaseIsLoading}
                                    progress={progress()}
                                    submit={() => setIsModal(true)}
                                    isAuth={isAuth}
                                    setIsError={setIsError}
                                    txHash={txHash}
                                />
                            </SubContainer>
                        )}
                    </Fragment>
                )}
            </div>
            <Popup
                onConfirmName="Purchase"
                onConfirm={submit}
                name="Confirm Node Purchase"
                isOpen={isModal}
                isWait={isWait}
                isLoading={purchaseIsLoading}
                onClose={() => setIsModal(false)}
            >
                <PopupContent
                    tier={tier}
                    value={amount}
                    token={selectedToken}
                />
            </Popup>
        </div>
    );
};

const TierInfo = ({
    id,
    tier,
    amount,
    setAmount,
    isSuccess,
    isError,
    isAvailable,
    progress,
    submit,
    isAuth,
    isLoading,
    selectedToken,
    optionsTokens,
    setSelectedToken,
    userBalances,
    setIsError,
    txHash,
}: {
    id: any;
    tier: any;
    amount: any;
    setAmount: any;
    isSuccess: boolean;
    isError: boolean;
    isAvailable: boolean;
    progress: string;
    submit: any;
    isAuth: boolean;
    isLoading?: boolean;
    selectedToken: SelectOptionToken;
    optionsTokens: SelectOptionToken[];
    setSelectedToken: React.Dispatch<React.SetStateAction<SelectOptionToken>>;
    userBalances: UserBalances | undefined;
    setIsError: any;
    txHash: string;
}) => (
    <div className={styles.tierInfoContainer}>
        <TierHeader id={id} />
        {isSuccess ? (
            <SuccessMessage
                tier={tier}
                amount={amount}
                hash={txHash}
                token={selectedToken}
            />
        ) : (
            <Fragment>
                <NodeSelection
                    tier={tier}
                    value={amount}
                    optionsTokens={optionsTokens}
                    selectedToken={selectedToken}
                    setSelectedToken={setSelectedToken}
                    userBalances={userBalances}
                    setValue={setAmount}
                    progress={progress}
                />
                {isError && (
                    <div className={styles.errorContainer}>
                        <Message
                            icon={errorIcon}
                            fontSize={16}
                            message="Purchase transaction failed. Please try again later."
                        />
                    </div>
                )}
                <TotalPrice
                    selectedToken={selectedToken}
                    icon={selectedToken?.icon}
                    token={selectedToken?.subLabel}
                    tier={tier}
                    value={amount}
                />
                <div className={styles.btnContainer}>
                    <CustomButton
                        isDisable={
                            !tier?.isPublicSale &&
                            (!isAuth || !amount || !isAvailable)
                        }
                        text={
                            !isAuth
                                ? "Connect your wallet to participate"
                                : !tier?.isPublicSale
                                ? tier?.private_left == 0
                                    ? "Public sale not started"
                                    : "Purchase"
                                : !amount
                                ? "Enter an Amount to Continue"
                                : "Purchase"
                        }
                        color="#FFFFFF"
                        backgroundColor={
                            !tier?.isPublicSale &&
                            (!isAuth || !amount || !isAvailable)
                                ? "#DCDEDC"
                                : "#009B5B"
                        }
                        onClick={submit}
                        isLoading={isLoading}
                        type="button"
                    />
                </div>
                <div style={{ marginTop: 5 }}>
                    <p>
                        By purchasing Node Licenses, you accept the{" "}
                        <a
                            style={{ color: "green" }}
                            href={"https://www.wirexpaychain.com/terms"}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Wirex Pay Node Sale Terms & Conditions
                        </a>
                    </p>
                </div>
            </Fragment>
        )}
    </div>
);

// @ts-ignore
const TierHeader = ({ id }) => (
    <div className={styles.tierHeader}>
        <div className={styles.tierHeaderInfo}>
            <img src={tierIcon} alt="tierIcon" />
            <span>tier {id}</span>
        </div>
        <div className={styles.tierHeaderIcon}>
            <img src={polygon} alt="polygon" />
        </div>
    </div>
);

// @ts-ignore
const SuccessMessage = ({ tier, hash, token, amount }) => (
    <div className={styles.successContainer}>
        <img src={success} alt="success" />
        <h3>Node(s) successfully purchased</h3>
        <div className={styles.tableInfo}>
            <PurchasedNodesInfo value={amount} />
            <PricePerNode tier={tier} token={token} value={amount} />
            <TotalPurchased tier={tier} value={amount} token={token} />
            <TransactionInfo hash={hash} />
            <div className={styles.btnContainerTable}>
                <CustomButton
                    text="View More Tiers"
                    color="#212323"
                    backgroundColor="#EFF1F2"
                    type="link"
                    url="/tiers"
                />
            </div>
        </div>
    </div>
);

// @ts-ignore
const PurchasedNodesInfo = ({ value }) => (
    <div className={styles.tableInfoNode}>
        <p>Purchased nodes amount</p>
        <p>
            <img src={nodes} alt="nodes" />
            <span>{value}</span>
        </p>
    </div>
);

// @ts-ignore
const TransactionInfo = ({ hash }) => (
    <div className={styles.tableInfoNode}>
        <p>View transaction on block explorer</p>
        <p>
            <a href={`https://polygonscan.com/tx/${hash}`}>TX</a>
        </p>
    </div>
);

// @ts-ignore
const PricePerNode = ({ tier, token, value }) => (
    <div className={styles.tableInfoPricePerNode}>
        <p>Price per node</p>
        <p>
            <img
                style={{ maxWidth: "24px" }}
                src={token?.icon}
                alt="token icon"
            />
            <span>{`${token?.label} ${token?.subLabel}`}</span>
        </p>
    </div>
);

// @ts-ignore
const TotalPurchased = ({ tier, value, token }) => (
    <div className={styles.total}>
        <p className={styles.bold}>Total Purchased</p>
        <p>
            <img
                style={{ maxWidth: "24px" }}
                src={token?.icon}
                alt="token icon"
            />
            <p>{`${token?.label * value} ${token?.subLabel}`}</p>
        </p>
    </div>
);

const NodeSelection = ({
    tier,
    value,
    setValue,
    progress,
    selectedToken,
    setSelectedToken,
    optionsTokens,
    userBalances,
}: {
    tier: any;
    value: string;
    setValue: any;
    progress: string;
    selectedToken: SelectOptionToken;
    setSelectedToken: React.Dispatch<React.SetStateAction<SelectOptionToken>>;
    optionsTokens: SelectOptionToken[];
    userBalances: UserBalances | undefined;
}) => {
    const unformattedBalance =
        userBalances?.[selectedToken?.value?.toUpperCase()] ?? "0";

    return (
        <>
            <h3>Select the nodes amount you wish to purchase</h3>
            <div className={styles.valueContainer}>
                <img
                    width="48px"
                    style={{ border: "none", marginBottom: "0" }}
                    src={selectedToken?.icon}
                    alt="selected token icon"
                />
                <div style={{ display: "flex" }}>
                    <CustomSelect
                        isStyledSelect
                        value={selectedToken}
                        onChange={(value: any) => setSelectedToken(value)}
                        options={optionsTokens}
                        isSearchable={false}
                        label=""
                    />
                </div>
                <div className={styles.nodeContainer}>
                    <p>/ per</p>
                    <img src={nodeIcon} alt="node icon" />
                    <p> Node</p>
                </div>
                {tier?.privateLeft && !tier?.isPublicSale ? (
                    <div className={styles.progressContainer}>
                        <p>Nodes Allowance</p>
                        <ProgressBar
                            total={+tier?.sale_limit}
                            current={+tier?.privateLeft}
                        />
                        <div className={styles.progressItem}>
                            <div className={styles.inner}>
                                <h3>
                                    <span
                                        style={{ color: getColor(+progress) }}
                                    >
                                        {tier?.privateLeft}
                                    </span>{" "}
                                    / {tier?.sale_limit}
                                </h3>
                                <span
                                    className={styles.totalAvailable}
                                >{`(total nodes available in tier: ${tier?.limit_emission})`}</span>
                            </div>
                            <p className={styles.progress}>{progress}%</p>
                        </div>
                    </div>
                ) : (
                    <div className={styles.progressContainer}>
                        <p>Nodes Sold</p>
                        <ProgressBar
                            total={+tier?.limit_emission}
                            current={+tier?.current_bought}
                        />
                        <div className={styles.progressItem}>
                            <h3>
                                <span style={{ color: getColor(+progress) }}>
                                    {tier?.current_bought}
                                </span>{" "}
                                / {tier?.limit_emission}
                            </h3>
                            <p className={styles.progress}>{progress}%</p>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.inputContainer}>
                <CustomInput
                    icon={selectedToken?.icon}
                    min={"1"}
                    half={
                        tier?.privateLeft && !tier?.isPublicSale
                            ? String(Math.floor(tier?.privateLeft / 2))
                            : String(
                                  Math.max(
                                      Math.min(
                                          Math.floor(
                                              (+tier?.limit_emission -
                                                  +tier?.current_bought) /
                                                  2
                                          ),
                                          Math.floor(+tier?.sale_limit / 2),
                                          Math.floor(
                                              parseFloat(unformattedBalance) /
                                                  +selectedToken?.label /
                                                  2
                                          )
                                      ),
                                      1
                                  )
                              )
                    }
                    max={
                        tier?.privateLeft && !tier?.isPublicSale
                            ? String(tier?.privateLeft)
                            : String(
                                  Math.max(
                                      Math.min(
                                          Math.floor(
                                              +tier?.limit_emission -
                                                  +tier?.current_bought
                                          ),
                                          Math.floor(+tier?.sale_limit),
                                          Math.floor(
                                              parseFloat(unformattedBalance) /
                                                  +selectedToken?.label
                                          )
                                      ),
                                      1
                                  )
                              )
                    }
                    currency={false}
                    value={value}
                    balance={unformattedBalance}
                    token={selectedToken?.subLabel}
                    nodeValue={+formatToEth(+tier?.price_native_token)}
                    setValue={setValue}
                    placeholder={
                        tier?.privateLeft && !tier?.isPublicSale
                            ? String(tier?.privateLeft)
                            : String(
                                  Math.max(
                                      Math.min(
                                          Math.floor(
                                              +tier?.limit_emission -
                                                  +tier?.current_bought
                                          ),
                                          Math.floor(+tier?.sale_limit),
                                          Math.floor(
                                              parseFloat(unformattedBalance) /
                                                  +selectedToken?.label
                                          )
                                      ),
                                      1
                                  )
                              )
                    }
                    limit={(x: number) => {
                        if (tier?.privateLeft && !tier?.isPublicSale) {
                            return Math.min(x, tier?.privateLeft);
                        } else {
                            if (+tier?.saleLimit == 0) {
                                return Math.min(
                                    x,
                                    +tier?.limit_emission -
                                        +tier?.current_bought
                                );
                            } else {
                                return Math.min(x, +tier?.sale_limit);
                            }
                        }
                    }}
                    onKeyDown={(event: any) =>
                        (event?.keyCode === 69 || event?.keyCode === 190) &&
                        event?.preventDefault()
                    }
                    type="number"
                />
            </div>
        </>
    );
};

// @ts-ignore
const TotalPrice = ({ tier, value, icon, token, selectedToken }) => {
    const price = selectedToken?.label * value;

    return (
        <div className={styles.priceContainer}>
            <p>Total Price</p>
            {!value ? (
                <p style={{ color: "#DCDEDC" }}>-</p>
            ) : (
                <div className={styles.priceItem}>
                    <img width="24px" src={icon} alt="polygon" />
                    <p>
                        {price?.toFixed(2)} {token}
                    </p>
                </div>
            )}
        </div>
    );
};

// @ts-ignore
const PopupContent = ({ tier, value, token }) => (
    <div className={styles.popupContainer}>
        <p>
            You are about to purchase <span>{value} nodes</span> for{" "}
            <span>{`${token.label * value} ${token.subLabel}`}</span>
        </p>
        <div className={styles.popupInfo}>
            <img src={nodeIcon} alt="nodes" />
            <h2>Purchase {value} Nodes</h2>
            <div className={styles.popupInfoItem}>
                <p>
                    for <img src={token.icon} alt={`${token.value}`} />{" "}
                    {`${token.label * value} ${token.subLabel}`}
                </p>
            </div>
        </div>
    </div>
);

export default OneTierPage;
