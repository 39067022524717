import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from './context/auth.context';
import dotenv from 'dotenv';

dotenv.config();

// window.addEventListener('DOMContentLoaded', () => {
//     // @ts-ignore
//     if (window.WalletConnectProvider && !window.WalletConnectProvider.isWalletConnectUriRegistered()) {
//         // @ts-ignore
//         window.WalletConnectProvider.registerUriHandler();
//     }
// });

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        {/* <Web3ModalProvider> */}
            <AuthProvider>
                <App/>
            </AuthProvider>
        {/* </Web3ModalProvider> */}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
