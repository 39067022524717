import {createBrowserRouter, Outlet} from "react-router-dom";
import {MainTemplate} from "../components";
import {
    ConnectWalletPage,
    HomePageLoggedIn,
    TokenBridgePage,
    ActivityPage,
    TiersPage,
    OneTierPage,
    PurchasesPage, TransferLicensePage, HomeOffchainSale, PrivateSalePage, QRPage
} from "../pages";


export const privateRouter = createBrowserRouter([
    {
        element: (
            <MainTemplate>
                <Outlet/>
            </MainTemplate>
        ),
        children: [
            {
                path: "/connect-wallet",
                element: <ConnectWalletPage/>
            }, {
                path: "/",
                element: <HomePageLoggedIn/>
            }, {
                path: "/token-bridge",
                element: <TokenBridgePage/>
            }, {
                path: "/activity",
                element: <ActivityPage/>
            },
            {
                path: "/tiers",
                element: <TiersPage/>
            },
            {
                path: "/tiers/:id",
                element: <OneTierPage/>
            },
            {
                path: "/purchases",
                element: <PurchasesPage/>
            },
            {
                path: "/purchases/:id",
                element: <TransferLicensePage/>
            },
            {
                path: "/offchain",
                element: <HomeOffchainSale/>
            },
            {
                path: "/private-sale",
                element: <PrivateSalePage/>
            },
            {
                path: "/qr",
                element: <QRPage />
            }
        ]
    }
]);
