import React, {FC} from 'react';
import styles from './wallet.connect.success.module.scss';
import success from './../../assets/images/icon/success.svg';
import {CustomButton, SubContainer} from "../index";


const WalletConnectSuccess:FC = () => {
    return (
        <div className={styles.connectWalletContainer}>
            <h1>Connect Wallet</h1>
            <SubContainer>
                <div className={styles.connectWalletItem}>
                    <img src={success} alt="success"/>
                    <h3>Wallet successfully connected</h3>
                    <div className={styles.btnContainer}>
                        <CustomButton text="Done" backgroundColor="#009B5B" color="#FFF" url="/" type="link"/>
                    </div>
                </div>
            </SubContainer>
        </div>
    );
};

export default WalletConnectSuccess;