import { WalletService } from "../services/wallet.service";
import ABI from "./../abi/cardona-NodeTiers-abi.json";
import { BrowserProvider, Contract, ethers } from "ethers";
import registerABI from "../abi/cardona-ContractRegister-abi.json";
import {
    ContractRegister,
    ContractRegister__factory,
} from "../typechain-types";

export const checkTierPublicSaleAvailable = async (
    tierID: number,
    contract: Contract,
    nodeTiersAddress: string
) => {
    try {
        const walletService = new WalletService(nodeTiersAddress, ABI);
        const { account, signer } = await walletService.connectWallet();

        if (!signer) {
            console.error("No signer or account found");
            return;
        }

        const tier = await contract.getTier(tierID);
        const { publicSaleAvailable, startSaleTS, endSaleTS } = tier;

        const currentTime = Math.floor(Date.now() / 1000);
        const isSaleActive =
            publicSaleAvailable &&
            currentTime >= startSaleTS &&
            currentTime <= endSaleTS;

        if (!isSaleActive) {
            console.error(
                "Public sale for this tier is not active or not available."
            );
            return;
        }
    } catch (error) {
        console.error(error);
    }
};
export function getProvider() {
    const provider = new ethers.JsonRpcProvider(
        process.env.REACT_APP_PROVIDER_URL
    );
    return provider;
}

export const getRegister = (signer?: any) => {
    const registerAddress = process.env.REACT_APP_CONTRACT_REGISTER_ADDRESS;
    const provider = getProvider();

    const registerContract = new ethers.Contract(
        registerAddress!,
        registerABI,
        signer ?? provider
    );
    return registerContract as unknown as ContractRegister;
};

export async function getContract(
    name: string,
    contractAbi: ethers.Interface | ethers.InterfaceAbi,
    registry: ethers.Contract | ContractRegister,
    runner?: ethers.ContractRunner | null | undefined
) {
    const contractData = await registry!.contractByName(name);

    return new ethers.Contract(contractData, contractAbi, runner);
}

export async function loadAddresses(
    registerAddress: string,
    provider: BrowserProvider
) {
    const register = ContractRegister__factory.connect(
        registerAddress,
        provider
    );
    const [ns, ne, nt, np, tr, po] = await Promise.all([
        register.contractByName("NodeSale"),
        register.contractByName("NodeERC721A"),
        register.contractByName("NodeTiers"),
        register.contractByName("NodePurchase"),
        register.contractByName("TokenRegister"),
        register.oraclePrice(),
    ]);
    const addresses: Addresses = {
        NodeSale: ns,
        NodeERC721A: ne,
        NodePurchase: np,
        NodeTiers: nt,
        TokenRegister: tr,
        PriceOracle: po,
    };
    return addresses;
}

export type Addresses = {
    NodeSale: string;
    NodeERC721A: string;
    NodeTiers: string;
    NodePurchase: string;
    TokenRegister: string;
    PriceOracle: string;
};
