// routers/privateRouter.tsx
import {createBrowserRouter, Outlet} from 'react-router-dom';
import {MainTemplate} from "../components";
import {HomeInitialPage, ConnectWalletPage, TiersPage, OneTierPage, HomeOffchainSale, QRPage} from "../pages";


export const publicRouter = createBrowserRouter([
    {
        element: (
            <MainTemplate>
                <Outlet/>
            </MainTemplate>
        ),
        children: [
            {
                path: "/",
                element: <HomeInitialPage/>,
            },
            {
                path: "/connect-wallet",
                element: <ConnectWalletPage/>
            },
            {
                path: "/tiers",
                element: <TiersPage/>
            },
            {
                path: "/tiers/:id",
                element: <OneTierPage/>
            },
            {
                path: "/offchain",
                element: <HomeOffchainSale/>
            },
            {
                path: "/qr",
                element: <QRPage />
            }
        ]
    }
]);

