import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './skeleton.tier.module.scss';

const SkeletonTier = () => {
    return (
        <div className={styles.tierContainer}>
            <div className={styles.tierContent}>
                <div className={styles.tierHeader}>
                    <div className={styles.tierId}>
                        <Skeleton height={22} width={87} borderRadius={20}/>
                    </div>
                    <div>

                    </div>
                </div>
                <div className={styles.tierInfo}>
                    <div style={{marginTop: 32}}><Skeleton height={48} width={48} circle/></div>
                    <h2><Skeleton height={22} width={167} borderRadius={20}/></h2>
                    <div className={styles.nodeContainer}>
                        <Skeleton height={14} width={99} borderRadius={20}/>
                    </div>
                    <div className={styles.valueContainer}>
                        <Skeleton height={14} width={99} borderRadius={20}/>
                        <div className={styles.valueContainerItem}>
                            <Skeleton height={22} width={281} borderRadius={20}/>
                        </div>
                    </div>
                    <div className={styles.btnContainer}>
                        <Skeleton height={52} width={281} borderRadius={8}/>
                        <div className={styles.btnItem}>
                            <Skeleton baseColor="#FFF" height={14} width={99} borderRadius={20}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonTier;
