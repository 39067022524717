import React, {FC} from 'react';
import styles from './wallet.address.icon.module.scss';
import metamask from './../../assets/images/icon/metamask-icon.svg';
import {useAuth} from "../../context/auth.context";

const WalletAddressIcon:FC = () => {

    const {address} = useAuth();

    return (
        <div className={styles.walletContainer}>
            <img src={metamask} alt="metamask"/>
            <p>{address?.slice(0, 5) + "..." + address?.slice(-5)}</p>
        </div>
    );
};

export default WalletAddressIcon;