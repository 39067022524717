import React, { ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./popup.module.scss";
import close from "./../../assets/images/icon/close.svg";
import { CustomButton } from "../index";

interface PopupProps {
  isOpen: boolean;
  isWait: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  isLoading?: boolean
  children: ReactNode;
  name: string;
  onConfirmName: string;
}

const Popup: React.FC<PopupProps> = ({
  isOpen,
  isWait,
  onClose,
  onConfirm,
  children,
  name,
  isLoading,
  onConfirmName,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }

    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.popupOverlay} onClick={handleOverlayClick}>
      <div className={styles.popup}>
        <div className={styles.popupHeader}>
          <div>
            <h3>{name}</h3>
          </div>
          <div>
            <button onClick={onClose}>
              <img src={close} alt="close" />
            </button>
          </div>
        </div>
        <div className={styles.popupContent}>{children}</div>
        <div className={styles.popupButtons}>
          <div className={styles.btnContainer}>
            <CustomButton
              text="Cancel"
              backgroundColor="#EFF1F2"
              color={isLoading ? "#D3D3D3" : "#212323"}
              type="button"
              onClick={onClose}
              isDisable={isLoading}
            />
          </div>
          {onConfirm && (
            <div className={styles.btnContainer}>
              <CustomButton
                text={onConfirmName}
                backgroundColor={isWait ? "#EFF1F2" : "#009B5B"}
                color={isWait ? "#212323" : "#FFFFFF"}
                type="button"
                onClick={onConfirm}
                isLoading={isLoading}
                isDisable={isWait}
              />
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Popup;
