import React from 'react';
import Select, { components, StylesConfig } from 'react-select';
import styles from './custom.select.module.scss';
import check from './../../assets/images/icon/check.svg';
import search from './../../assets/images/icon/search.svg';

export interface OptionType {
    value: string;
    label: string;
    subLabel: string;
}


const customStyles: StylesConfig<OptionType, false> = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? '#009B5B' : provided.borderColor,
        borderRadius: 8,
        height: 48,
        boxShadow: state.isFocused ? '0 0 0 1px #009B5B' : provided.boxShadow,
        '&:hover': {
            borderColor: state.isFocused ? '#009B5B' : provided.borderColor
        }
    }),
    // @ts-ignore
    option: (provided, state) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: null,
        color: state.isSelected ? '#212323' : 'inherit',
        '&:hover': {
            backgroundColor: 'lightgray',
            color: 'black',
        }
    })
};

const styledSelect: StylesConfig<OptionType, false> = {
    control: (provided, state) => ({
        ...provided,
        border: 'none',
        borderRadius: 8,
        height: 48,
        boxShadow:'transparent',
    }),
    menu: (base) => ({
        ...base,
        width: "max-content",
        minWidth: "100%"
   }),
    // @ts-ignore
    option: (provided, state) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: null,
        color: state.isSelected ? '#212323' : 'inherit',
        '&:hover': {
            backgroundColor: 'lightgray',
            color: 'black',
        }
    })
};

const Option: React.FC<any> = (props) => {
    return (
        <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' , justifyContent: "space-between", width: "100%"}}>
                <div style={{ display: 'flex', alignItems: 'center' , justifyContent: "space-between"}}>
                    <img width="20px" src={props.data.icon} alt="search" style={{marginRight: 6}}/>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span className={styles.label}>{props.data.label}&nbsp;</span>
                        
                        <span className={styles.subLabel}> {props.data.subLabel}</span>
                    </div>
                </div>
                <div>{props.isSelected && <img src={check} alt="check"/>}</div>
            </div>
        </components.Option>
    );
};

const SingleValue: React.FC<any> = (props) => {
    return (
        <components.SingleValue {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={props.data.icon} alt={props.data.label} style={{ marginRight: 6 }} />
                <span>{props.data.label}</span>
            </div>
        </components.SingleValue>
    );
};

const StyledSingleValue: React.FC<any> = (props) => {
    return (
        <components.SingleValue {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1>{props.data.label}&nbsp;</h1>
                <p style={{alignSelf: 'flex-end', marginBottom: '10px'}}>{props.data.subLabel}</p>
            </div>
        </components.SingleValue>
    );
};

interface OwnToProps {
    label: string
    isDisabled?:boolean
    options: OptionType[]
    value: OptionType | null
    isStyledSelect?: boolean
    isSearchable? :boolean
    onChange: (p: OptionType | null) => void
}

const CustomSelect: React.FC<OwnToProps> = ({label, isDisabled, options, value, isStyledSelect, onChange, isSearchable}) => {
 
    return (
        <div className={isStyledSelect ? styles.StyledSelectContainer : styles.selectContainer}>
            <p>{label}</p>
            <Select
                value={value}
                isSearchable={isSearchable}
                onChange={(newValue) => onChange(newValue)}
                defaultValue={options[0]}
                isDisabled={isDisabled}
                styles={isStyledSelect ? styledSelect : customStyles}
                options={options}
                components={{ Option, SingleValue: isStyledSelect ? StyledSingleValue : SingleValue }}
                placeholder="Select option..."
            />
        </div>
    );
};

export default CustomSelect;
