import React, {FC, useState} from 'react';
import styles from './activity.page.module.scss';
import {GoBackButton} from "../../components";
import {useAuth} from "../../context/auth.context";
import metamask from "../../assets/images/icon/metamask-icon.svg";
import polygon from "../../assets/images/logo/polygon.svg";
import ethereum from "../../assets/images/logo/etherium.svg";
import AccordionItem from "../../components/activity-item";
import {useWindowSize} from "../../hooks/useWindowSize.hook";

const ActivityPage: FC = () => {

    const {address, logout} = useAuth();
    const {width} = useWindowSize();

    const items = [
        {
            title: 'Bridge 0.9989 xETH',
            date: '2023-10-29, 15:32:45 (UTC +1)',
            status: 'Processing',
            iconFrom: ethereum,
            iconTo: polygon,
            fromToken: 'ETH',
            toToken: 'xETH',
            fromNetwork: 'Ethereum',
            toNetwork: 'Polygon',
            amount: 0.9989,
            gasFee: 0.0089
        },
        {
            title: 'Bridge 0.9989 xETH',
            date: '2023-10-29, 15:32:45 (UTC +1)',
            status: 'Finalized',
            iconFrom: ethereum,
            iconTo: polygon,
            fromToken: 'ETH',
            toToken: 'xETH',
            fromNetwork: 'Ethereum',
            toNetwork: 'Polygon',
            amount: 0.9989,
            gasFee: 0.0089
        },
        {
            title: 'Bridge 0.9989 xETH',
            date: '2023-10-29, 15:32:45 (UTC +1)',
            status: 'Initiated',
            iconFrom: ethereum,
            iconTo: polygon,
            fromToken: 'ETH',
            toToken: 'xETH',
            fromNetwork: 'Ethereum',
            toNetwork: 'Polygon',
            amount: 0.9989,
            gasFee: 0.0089
        },
    ];

    const [status, setStatus] = useState<'Processing' | 'Finalized' | 'Initiated' | 'All' | string>('All');


    return (
        <div className={styles.activityPage}>
            <GoBackButton/>
            <div className={styles.activityPageContainer}>
                <h1>Activity</h1>
                <div className={styles.walletInfo}>
                    <div className={styles.walletInfoItem}>
                        <img className={styles.polygon} src={polygon} alt="polygon"/>
                        <img src={metamask} alt="metamask"/>
                        <div>
                            <p>My Wallet</p>
                            <h4>{address?.slice(0, width as number > 567 ? 30 : 17)}...</h4>
                        </div>
                    </div>
                    <button onClick={logout}>Disconnect</button>
                </div>
                <div className={styles.containerName}>
                    <h3>Transactions</h3>
                    <div>
                        <span>Status:</span>
                        <select style={status === 'All' ? {width: 40} : {width: 100}} onChange={(event) => setStatus(event.target.value)} name="select">
                            <option value="All">All</option>
                            {items.map(({status: localStatus}, index) => <option key={index} value={localStatus}>{localStatus}</option>)}
                        </select>
                    </div>
                </div>
                <div>
                    {items.filter((el => (status !== 'All') ? el.status === status : items)).map((item, index) => (
                        <AccordionItem
                            key={index}
                            title={item.title}
                            date={item.date}
                            status={item.status}
                            iconFrom={item.iconFrom}
                            iconTo={item.iconTo}
                            fromToken={item.fromToken}
                            toToken={item.toToken}
                            fromNetwork={item.fromNetwork}
                            toNetwork={item.toNetwork}
                            amount={item.amount}
                            gasFee={item.gasFee}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ActivityPage;