import React, { FC } from "react";
import styles from "./tier.item.module.scss";
import tierIcon from "./../../assets/images/icon/tier-icon.svg";
import polygon from "./../../assets/images/logo/polygon.svg";
import nodeIcon from "./../../assets/images/icon/node-icon.svg";
import { CustomButton, ProgressBar } from "../index";
import { formatToEth } from "../../pages/one-tier-page";

interface OwnToProps {
    start_sale: string;
    end_sale: string;
    price_native_token: string;
    limit_emission: number;
    current_bought: number;
    total_nodes: number;
    sale_limit: number;
    id: string;
}

const TierItemPrivate: FC<OwnToProps> = ({
    sale_limit,
    limit_emission,
    current_bought,
    total_nodes,
    start_sale,
    end_sale,
    price_native_token,
    id,
}) => {
    const rem = total_nodes - current_bought;
    sale_limit = sale_limit > rem ? rem : sale_limit;
    limit_emission = limit_emission > rem ? rem : limit_emission;
    const isSaleNotStarted =
        new Date(start_sale).getTime() > new Date().getTime();
    const isSaleEnded =
        new Date(end_sale).getTime() < new Date().getTime() ||
        current_bought === total_nodes;
    const progress = (
        (limit_emission ? sale_limit / limit_emission : 0) * 100
    ).toFixed(2);
    const buttonText = isSaleNotStarted
        ? `Sale Starts ${start_sale}`
        : isSaleEnded
        ? "Sorry, Sold Out"
        : sale_limit == 0
        ? "Sold Out"
        : "Purchase";
    const buttonBgColor =
        isSaleNotStarted || isSaleEnded || sale_limit == 0
            ? "#DCDEDC"
            : "#009B5B";

    return (
        <div className={styles.tierContainer}>
            <div className={styles.tierContent}>
                <div className={styles.tierHeader}>
                    <div className={styles.tierId}>
                        <img src={tierIcon} alt="tier icon" />
                        <span>tier {id}</span>
                    </div>
                    <div>
                        <img src={polygon} alt="polygon logo" />
                    </div>
                </div>
                <div className={styles.tierInfo}>
                    <img src={polygon} alt="polygon logo" />
                    <h2>
                        {formatToEth(+price_native_token)} <span>ETH</span>
                    </h2>
                    <div className={styles.nodeContainer}>
                        <p>/ per</p>
                        <img src={nodeIcon} alt="node icon" />
                        <p> Node</p>
                    </div>
                    <div className={styles.valueContainer}>
                        <p>Node Allowance</p>
                        <ProgressBar
                            total={limit_emission}
                            current={sale_limit}
                        />
                        <div className={styles.valueContainerItem}>
                            <p>
                                <span>{sale_limit}</span> / {limit_emission}
                            </p>
                            <p className={styles.interest}>{progress}%</p>
                        </div>
                    </div>
                    <div className={styles.btnContainer}>
                        <CustomButton
                            text={buttonText}
                            backgroundColor={buttonBgColor}
                            type="link"
                            isDisable={
                                isSaleEnded ||
                                isSaleNotStarted ||
                                sale_limit == 0
                            }
                            color="#FFF"
                            padding={"14px 4px"}
                            url={`/tiers/${id}`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TierItemPrivate;
