import React, {FC} from 'react';
import styles from './sub.container.module.scss';

interface OwnToProps{
    children: JSX.Element
    paddingBottom?: number
}
const SubContainer:FC<OwnToProps> = ({children, paddingBottom}) => {
    return (
        <div style={{paddingBottom: paddingBottom}} className={styles.subContainer}>
            {children}
        </div>
    );
};

export default SubContainer;