import React, {FC} from 'react';
import {useNavigate} from "react-router-dom";
import polygon from "../../assets/images/logo/polygon.svg";
import metamask from "../../assets/images/icon/metamask-icon.svg";
import noConnected from "../../assets/images/icon/no-connected.svg";
import smallWallet from "../../assets/images/icon/small-wallet.svg";
import {useAuth} from "../../context/auth.context";
import styles from './wallet.info.module.scss'
import {useWindowSize} from "../../hooks/useWindowSize.hook";

const WalletInfo:FC = () => {

    const navigate = useNavigate();
    const {isAuth, address, logout} = useAuth();
    const {width} = useWindowSize();

    const logOut = () => {
        logout();
        navigate('/')
    }

    return (
        <div className={styles.walletInfo}>
            <div style={!isAuth ? {borderRight: 'none'} : {}} className={styles.walletInfoItem}>
                {isAuth && <img className={styles.polygon} src={polygon} alt="polygon"/>}
                <img src={isAuth ? metamask : noConnected} alt="metamask"/>
                {!isAuth ?
                    <h4>No connected wallet.<br/>Connect your wallet to participate</h4> :
                    <div>
                        <p>My Wallet</p>
                        <h4>{address?.slice(0, width as number > 567 ? 30 : 17)}...</h4>
                    </div>}
            </div>
            <button style={!isAuth ? {backgroundColor: '#009B5B', color: '#FFF'} : {}}
                    onClick={!isAuth ? () => navigate('/connect-wallet') : logOut}>{!isAuth &&
                <img src={smallWallet} alt="wallet"/>}
                <span>{!isAuth ? 'Connect Wallet' : 'Disconnect'}</span>
            </button>
        </div>
    );
};

export default WalletInfo;
