import React, { FC, useEffect, useState, useCallback } from "react";
import styles from "./home.offchain.page.module.scss";
import {
    CustomButton,
    Message,
    PasswordInput,
    QRCodeComponent,
    SubContainer,
} from "../../components";
import nodeIcon from "./../../assets/images/icon/node-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import successIcon from "./../../assets/images/icon/success-info.svg";
import { OffchainService } from "../../services/offchain.service";
import errorIcon from "./../../assets/images/icon/error-small-icon.svg";
import smallSuccessIcon from "./../../assets/images/icon/success-icon.svg";
import { useAuth } from "../../context/auth.context";
import { getContract, getRegister } from "../../utils";
import nodeSaleABI from "../../abi/cardona-NodeSale-abi.json";
import { WalletService, WS } from "../../services/wallet.service";
import { ethers } from "ethers";

const useQuery = () => new URLSearchParams(useLocation().search);

const HomeOffchainSale: FC = () => {
    const navigate = useNavigate();
    const { address } = useAuth();
    const [closeInfo, setCloseInfo] = useState(false);
    const [password, setPassword] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoading, setIsloading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isPasswordIncorrect, setIsPasswordIncorrect] =
        useState<boolean>(false);
    const [claimData, setClaimData] = useState<{
        uuid: string;
        sig: string;
        amount: number;
        tier_id: string;
    } | null>(null);
    const query = useQuery();
    const uuid = query.get("uuid");

    useEffect(() => {
        if (!uuid) {
            navigate("/");
        }
    }, [uuid, navigate]);

    useEffect(() => {
        if (address) {
            setCloseInfo(true);
        }
    }, [address]);

    const handleNavigation = useCallback(() => {
        navigate("/connect-wallet", {
            state: { from: `/offchain?uuid=${uuid}` },
        });
    }, [navigate, uuid]);

    const checkPassword = useCallback(async () => {
        if (!password) return;
        setIsPasswordIncorrect(false);
        try {
            setIsloading(true);
            const offchainService = new OffchainService();
            const claim = await offchainService.proceedClaim(
                uuid as string,
                address as string,
                password,
                true
            );
            if (claim.errors && claim.errors[0].error_code === "500") {
                const approval = await offchainService.getApproval(
                    uuid as string
                );
                if (approval.errors && claim.errors[0].error_code === "500") {
                    throw new Error("Password is incorrect.");
                }
                const approvalClaim = {
                    uuid: uuid as string,
                    sig: approval.signature!,
                    tier_id: approval.tier_id!,
                    amount: approval.amount!,
                };
                setClaimData(approvalClaim);
            } else {
                setClaimData(claim);
            }
        } catch (error) {
            console.error(error);
            setIsPasswordIncorrect(true);
        } finally {
            setIsloading(false);
        }
    }, [password]);

    const submit = useCallback(async () => {
        if (!claimData) return;
        setIsError(false);
        try {
            setIsloading(true);
            const walletService = WS;
            const { signer } = await walletService.connectWallet();

            const register = getRegister(signer);
            const nodeSaleContract = await getContract(
                "NodeSale",
                nodeSaleABI,
                register,
                signer
            );
            // @ts-ignore
            const bytesUUID = ethers.toBeArray("0x" + uuid.replace(/-/g, ""));
            const { sig, tier_id, amount } = claimData;
            const tx = await nodeSaleContract.buyTierOffchain(
                tier_id,
                amount,
                bytesUUID,
                sig
            );
            await tx.wait();
            setIsSuccess(true);
        } catch (error) {
            console.error(error);
            setIsError(true);
        } finally {
            setIsloading(false);
        }
    }, [password, uuid, address, claimData]);

    return (
        <div className={styles.homeOffchain}>
            <h1
                dangerouslySetInnerHTML={{
                    __html: address
                        ? "Enter Password"
                        : "WIREX PAY <span>Node Sale!</span>",
                }}
            />
            <h3>
                {address
                    ? "Enter your password to claim the purchase."
                    : "Connect your wallet to claim the purchase!"}
            </h3>
            {address && closeInfo && (
                <div className={styles.messageContainer}>
                    <Message
                        icon={successIcon}
                        callback={() => setCloseInfo(false)}
                        message="Wallet successfully connected"
                        backgroundColor="#00D25033"
                        color="#212323"
                    />
                </div>
            )}
            <SubContainer>
                <div>
                    <div className={styles.content}>
                        <div className={styles.info}>
                            <SubContainer>
                                <>
                                    <div className={styles.content}>
                                        <div className={styles.info}>
                                            <h3>
                                                UUID: <br />
                                                {uuid}
                                            </h3>
                                            {claimData?.amount &&
                                                claimData.tier_id && (
                                                    <div
                                                        className={
                                                            styles.infoItem
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.infoItemContainer
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    styles.valueName
                                                                }
                                                            >
                                                                Tier
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles.value
                                                                }
                                                            >
                                                                {
                                                                    claimData.tier_id
                                                                }
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.infoItemContainer
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    styles.valueName
                                                                }
                                                            >
                                                                Amount
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles.value
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        nodeIcon
                                                                    }
                                                                    alt="node"
                                                                />
                                                                {
                                                                    claimData?.amount
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                        <div className={styles.qrCodeContainer}>
                                            <QRCodeComponent
                                                uuid={uuid as string}
                                            />
                                        </div>
                                    </div>
                                    {address && (
                                        <PasswordInput
                                            placeholder="Enter your password"
                                            label="Password"
                                            value={password}
                                            setValue={setPassword}
                                        />
                                    )}
                                    {isPasswordIncorrect && (
                                        <div className={styles.errorContainer}>
                                            <Message
                                                icon={errorIcon}
                                                message="Incorrect password. Please try again."
                                            />
                                        </div>
                                    )}
                                    {!isPasswordIncorrect &&
                                        claimData &&
                                        !isSuccess && (
                                            <div
                                                className={
                                                    styles.messageContainer
                                                }
                                            >
                                                <Message
                                                    icon={successIcon}
                                                    message="Verification passed. Proceed to claim."
                                                    backgroundColor="#00D25033"
                                                    color="#212323"
                                                />
                                            </div>
                                        )}
                                    {isError && (
                                        <div className={styles.errorContainer}>
                                            <Message
                                                icon={errorIcon}
                                                message="Something went wrong. Please try again."
                                            />
                                        </div>
                                    )}
                                    {isSuccess && (
                                        <div
                                            className={styles.successContainer}
                                        >
                                            <Message
                                                backgroundColor="#00D25033"
                                                color="#212323"
                                                icon={smallSuccessIcon}
                                                message="Success! Your purchase has been claimed."
                                            />
                                        </div>
                                    )}
                                    <div className={styles.btnContainer}>
                                        <CustomButton
                                            onClick={
                                                isSuccess
                                                    ? () =>
                                                          navigate("/purchases")
                                                    : address && password
                                                    ? claimData
                                                        ? submit
                                                        : checkPassword
                                                    : handleNavigation
                                            }
                                            text={
                                                isSuccess
                                                    ? "To My Purchases"
                                                    : address
                                                    ? claimData
                                                        ? "Claim"
                                                        : "Check password"
                                                    : "Connect Wallet"
                                            }
                                            color={
                                                isSuccess ? "#212323" : "#FFF"
                                            }
                                            backgroundColor={
                                                isSuccess
                                                    ? "#EFF1F2"
                                                    : "#009B5B"
                                            }
                                            isLoading={isLoading}
                                            type="button"
                                        />
                                    </div>
                                </>
                            </SubContainer>
                        </div>
                    </div>
                </div>
            </SubContainer>
        </div>
    );
};

export default HomeOffchainSale;
