import React, { FC, useEffect, useState } from "react";
import { SubContainer } from "../../components";
import styles from "./home.page.module.scss";
import CustomButton from "../../components/custom-button";
import arrow from "./../../assets/images/icon/arrow.svg";
import plus from "./../../assets/images/icon/plus-icon.svg";
import { getProvider, getRegister } from "../../utils";
import { NodeERC721A__factory } from "../../typechain-types";

const HomeInitialPage: FC = () => {
    const [soldNodes, setSoldNodes] = useState<number>(0);

    useEffect(() => {
        const getTotalSupply = async (ret: number) => {
            const register = getRegister();
            try {
                const nodeERC721A = NodeERC721A__factory.connect(
                    await register.contractByName("NodeERC721A"),
                    register.runner
                );
                const totalSupply = await nodeERC721A.totalSupply();
                setSoldNodes(Number(totalSupply));
            } catch {
                await new Promise((f) => setTimeout(f, 500));
                ret++;
                if (ret < 5) {
                    getTotalSupply(ret);
                }
            }
        };
        getTotalSupply(0);
    }, []);

    return (
        <div className={styles.home}>
            <h1>
                WIREX PAY <span>Node Sale!</span>
            </h1>
            <h2 style={{ textAlign: "center" }}>
                <span>{soldNodes} Nodes Sold</span>
            </h2>
            <div style={{ textAlign: "center", paddingBottom: 10 }}>
                <p style={{ marginBottom: 10 }}>
                    The much-anticipated Wirex Pay Node Sale is officially
                    underway!
                </p>
                <p style={{ marginBottom: 10 }}>
                    Whitelist Sale Launch: August 5th, at 12:00 PM UTC
                </p>
                <p style={{ marginBottom: 10 }}>
                    Public Sale Launch: August 8th, at 12:00 PM UTC
                </p>
            </div>
            <SubContainer>
                <div className={styles.content}>
                    <h2>Connect your wallet to start</h2>
                    <div
                        className={styles.btnContainer}
                        style={{ marginBottom: 32 }}
                    >
                        <CustomButton
                            maxWidth={446}
                            url="/connect-wallet"
                            icon={plus}
                            iconPosition="left"
                            type="link"
                            backgroundColor="#009B5B"
                            color="#FFF"
                            text="Connect Wallet"
                        />
                    </div>
                    <div className={styles.btnContainer}>
                        <CustomButton
                            maxWidth={446}
                            icon={arrow}
                            iconPosition="right"
                            url="/tiers"
                            type="link"
                            backgroundColor="#EFF1F2"
                            color="#212323"
                            text="View Tiers"
                        />
                    </div>
                </div>
            </SubContainer>
        </div>
    );
};

export default HomeInitialPage;
