import React, {FC} from 'react';
import styles from './message.module.scss';
import errorIcon from './../../assets/images/icon/error-icon.svg';
import closeIcon from './../../assets/images/icon/close-icon.svg';

interface OwnToProps{
    name?: string
    message: string
    callback?: () => void
    fontSize?: number
    icon?: string
    backgroundColor?:string
    color?: string
}

const Message:FC<OwnToProps> = ({name, message, callback, icon, fontSize,backgroundColor, color}) => {
    return (
        <div style={{
            ... (fontSize ? {padding: '13px', borderRadius: 32} : {}),
            backgroundColor: backgroundColor
        }}  className={styles.errorContainer}>
            <div className={styles.errorMessageContainer}>
                <img style={fontSize ? {marginRight: 8} : {}} src={icon ? icon : errorIcon} alt="error"/>
                <div>
                    {name && <h4>{name}</h4>}
                    <p style={{...(fontSize ? {fontSize: fontSize} : {}), color: color}}>{message}</p>
                </div>
            </div>
            <div className={styles.errorBtnContainer}>
                {callback && <button onClick={callback}>
                    <img src={closeIcon} alt="close"/>
                </button>}
            </div>
        </div>
    );
};

export default Message;