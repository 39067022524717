import React, {FC} from 'react';
import styles from './go.back.button.module.scss';
import goBack from './../../assets/images/icon/go-back.svg';
import {useNavigate} from "react-router-dom";

const GoBackButton:FC = () => {

    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(-1)} className={styles.goBackContainer}>
            <img src={goBack} alt="go back"/>
            <span>Go Back</span>
        </div>
    );
};

export default GoBackButton;