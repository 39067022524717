import {
    EthereumProvider,
    EthereumProviderOptions,
} from "@walletconnect/ethereum-provider";
import detectEthereumProvider from "@metamask/detect-provider";
import { BrowserProvider, ethers, JsonRpcSigner } from "ethers";
import { utils } from "web3";

export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID!;
export const PROVIDER_URL = process.env.REACT_APP_PROVIDER_URL!;
export const BLOCK_EXPLORER_URL = process.env.REACT_APP_BLOCK_EXPLORER_URL!;

const decimalToHex = (decimal: number) => `0x${decimal.toString(16)}`;
const currentChainId = decimalToHex(Number(CHAIN_ID));
const projectId = process.env.REACT_APP_PROJECT_ID || "";
const url = process.env.REACT_APP_ORIGIN_URL || "http://localhost:3000/";

const metadata = {
    name: "nodeSale",
    description: "Node Sale",
    url: url,
    icons: [""],
};

const chain = [
    {
        chainId: CHAIN_ID,
        chainName: "Polygon POS",
        rpcUrls: [PROVIDER_URL],
        nativeCurrency: {
            name: "Matic",
            symbol: "MATIC",
            decimals: 18,
        },
        blockExplorerUrls: [BLOCK_EXPLORER_URL],
    },
];

type ConnectReturn = Promise<{
    account: string;
    signer: JsonRpcSigner;
    ethersProvider: BrowserProvider;
}>;

export const walletConnectOptions: EthereumProviderOptions = {
    projectId,
    optionalChains: [Number(CHAIN_ID)],
    rpcMap: {
        [Number(CHAIN_ID)]: PROVIDER_URL,
    },
    metadata,
    methods: [
        "eth_sendTransaction",
        "personal_sign",
        "eth_requestAccounts",
        "eth_sign",
        "transfer",
        "eth_chainId",
        "eth_sendSignedTransaction",
        "wallet_switchEthereumChain",
        "wallet_addEthereumChain",
    ],
    events: ["connect", "disconnect"],
    showQrModal: true,
    qrModalOptions: {
        themeMode: "light",
    },
};

class WalletService {
    private metamaskProvider: any;
    private provider: BrowserProvider | null;
    private account: string;
    private signer: JsonRpcSigner | null;

    private contractAddress: string | undefined;
    private contractABI: any[] | undefined;

    constructor(contractAddress?: string, contractABI?: any[]) {
        this.metamaskProvider = null;
        this.provider = null;
        this.account = "";
        this.signer = null;

        this.contractAddress = contractAddress;
        this.contractABI = contractABI;
    }

    async getProvider() {
        return this.provider;
    }

    isConnected() {
        if (this.signer && this.provider) {
            return true;
        }
        return false;
    }

    async initExtension() {
        try {
            this.metamaskProvider = await detectEthereumProvider();

            if (!this.metamaskProvider) {
                this.metamaskProvider = null;
                this.metamaskProvider = null;
                throw new Error("MetaMask not detected");
            }

            this.provider = new BrowserProvider(this.metamaskProvider);
        } catch (error) {
            this.metamaskProvider = null;
            console.error("An error occurred while detecting MetaMask:", error);
            return;
        }

        try {
            // @ts-ignore
            if (window.ethereum) {
                // @ts-ignore
                window.ethereum.on("chainChanged", () => {
                    // prevent reloading on connect wallet page to not break flow
                    const path = window?.location?.pathname?.split("/")?.[1];
                    if (path !== "connect-wallet") window.location.reload();
                });
                // @ts-ignore
                window.ethereum.on("accountsChanged", () => {
                    window.location.reload();
                });
            }
        } catch (error) {
            console.error(
                "An error occurred while initializing ethersWeb3Provider:",
                error
            );
            this.provider = null;
        }
    }

    async connectWallet(): ConnectReturn {
        if (this.provider && this.signer) {
            return {
                account: this.account,
                signer: this.signer,
                ethersProvider: this.provider,
            };
        }
        try {
            await this.initExtension();
            return await this.connectMetaMask();
        } catch (err) {
            try {
                return await this.connectWalletConnect();
            } catch (err) {
                throw new Error("Wallet not detected");
            }
        }
    }

    async connectMetaMask(): ConnectReturn {
        if (this.metamaskProvider) {
            try {
                const chainId = await this.metamaskProvider.request({
                    method: "eth_chainId",
                });
                if (chainId !== CHAIN_ID) {
                    try {
                        await this.metamaskProvider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: CHAIN_ID }],
                        });
                        await this.metamaskProvider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: CHAIN_ID }],
                        });
                    } catch (switchError: any) {
                        if (switchError?.code === 4902) {
                            await this.metamaskProvider.request({
                                method: "wallet_addEthereumChain",
                                params: chain,
                            });
                            await this.metamaskProvider.request({
                                method: "wallet_addEthereumChain",
                                params: chain,
                            });
                        } else {
                            throw switchError;
                        }
                    }
                }
                const accounts = await this.metamaskProvider.request({
                    method: "eth_requestAccounts",
                });

                this.provider = new ethers.BrowserProvider(
                    this.metamaskProvider
                );

                this.signer = await this.provider.getSigner();

                return {
                    account: accounts[0],
                    signer: this.signer,
                    ethersProvider: this.provider,
                };
            } catch (error) {
                this.signer = null;
                this.provider = null;
                throw new Error("Failed to connect to MetaMask");
            }
        } else {
            throw new Error("MetaMask not detected");
        }
    }

    async connectWalletConnect(): ConnectReturn {
        try {
            const provider = await EthereumProvider.init(walletConnectOptions);
            await provider.connect();

            const ethersWeb3Provider = new ethers.BrowserProvider(provider);
            const connectedNetwork = await ethersWeb3Provider.getNetwork();
            const connectedChainId = decimalToHex(
                Number(connectedNetwork.chainId)
            );

            if (connectedChainId !== currentChainId) {
                try {
                    await provider.request({
                        method: "wallet_addEthereumChain",
                        params: chain,
                    });
                } catch (err: any) {
                    console.log(err);
                    console.log(err);
                    throw new Error("Error adding network");
                }
            }

            let signer: JsonRpcSigner;
            try {
                signer = await ethersWeb3Provider.getSigner();
            } catch (err) {
                console.log(err);
                console.log(err);
                throw new Error("WalletConnect error!");
            }

            this.signer = signer;
            this.provider = ethersWeb3Provider;

            const address = await signer.getAddress();

            return {
                account: address,
                signer: signer,
                ethersProvider: ethersWeb3Provider,
            };
        } catch (error) {
            throw new Error("Error connecting to WalletConnect");
        }
    }

    public async signMessage(signer: JsonRpcSigner): Promise<string> {
        if (!this.provider) {
            throw new Error("provider is not initialized");
        }

        if (signer.address) {
            let signedMessage;
            try {
                signedMessage = await signer.signMessage(
                    `Please Sign to access WIREX PAY NODE SALE using your wallet: ${signer.address}`
                );
                return signedMessage;
            } catch (err: any) {
                console.error(err);
                throw new Error("Failed to sign message");
            }
        } else {
            throw new Error("No wallet account");
        }
    }

    async getBalance(account: string) {
        if (!this.provider) {
            throw new Error("Web3 is not initialized");
        }
        try {
            const address = await this.account; // Get the address associated with the signer
            const balance = await this.provider.getBalance(address); // Get the balance of that address
            return utils.fromWei(balance, "ether");
        } catch (error) {
            throw new Error("Failed to get balance");
        }
    }

    async getContract(name: string) {
        if (!this.provider) {
            throw new Error("Provider is not initialized");
        }
        const ethersProvider = this.provider;
        const signer = await ethersProvider.getSigner();
        const contract = new ethers.Contract(
            this.contractAddress as string,
            this.contractABI as any,
            signer
        );

        try {
            const address = await contract.contractByName(name);
            return address;
        } catch (error) {
            throw new Error("Failed to get contract");
        }
    }

    async getAllContract() {
        if (!this.provider) {
            throw new Error("Provider is not initialized");
        }

        const ethersProvider = this.provider;
        const signer = await ethersProvider.getSigner();
        const contract = new ethers.Contract(
            this.contractAddress as string,
            this.contractABI as any,
            signer
        );
        try {
            const contracts = await contract.contracts();
            return contracts;
        } catch (error) {
            throw new Error("Failed to get contracts");
        }
    }
}

export { WalletService };

export const WS = new WalletService();
