import React, { FC, Fragment } from "react";
import styles from "./custom.button.module.scss";
import { useNavigate } from "react-router-dom";

interface OwnToProps {
    text: string;
    icon?: string;
    backgroundColor: string;
    iconPosition?: "left" | "right";
    onClick?: () => void;
    url?: string | any;
    type: "button" | "link" | "externalLink";
    color?: string;
    maxWidth?: number;
    width?: string | number;
    isDisable?: boolean;
    padding?: string | number;
    marginRight?: number;
    isLoading?: boolean;
}

const CustomButton: FC<OwnToProps> = ({
    text,
    url,
    backgroundColor,
    onClick,
    icon,
    iconPosition,
    type,
    color,
    maxWidth,
    width,
    isDisable,
    isLoading,
    padding,
    marginRight,
}) => {
    const buttonStyles = { backgroundColor, maxWidth, width, color, padding };
    const iconStyles =
        iconPosition === "left"
            ? { marginRight: marginRight ? marginRight : 10 }
            : { marginLeft: 10 };
    const navigate = useNavigate();

    const handleClick = () => {
        if (isDisable || isLoading) return;
        if (type === "link") {
            navigate(url as string);
        }
        if (type === "externalLink") {
            return;
        } else if (onClick) {
            onClick();
        }
    };
    if (type === "externalLink")
        return (
            <a
                className={styles.customButton}
                style={{ ...buttonStyles, textDecoration: "none" }}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
            >
                {icon && iconPosition === "left" && (
                    <img style={iconStyles} src={icon} alt="icon" />
                )}
                <span style={{ color }}>{text}</span>
                {icon && iconPosition === "right" && (
                    <img style={iconStyles} src={icon} alt="icon" />
                )}
            </a>
        );

    return (
        <div
            className={styles.customButton}
            style={buttonStyles}
            onClick={handleClick}
        >
            {isLoading ? (
                <div className={styles.spinner}></div>
            ) : (
                <Fragment>
                    {icon && iconPosition === "left" && (
                        <img style={iconStyles} src={icon} alt="icon" />
                    )}
                    <span style={{ color }}>{text}</span>
                    {icon && iconPosition === "right" && (
                        <img style={iconStyles} src={icon} alt="icon" />
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default CustomButton;
