import React, {FC} from 'react';
import {Footer, Header} from "../index";
import styles from './main.template.module.scss';

interface OwnToProps {
    children: JSX.Element
}

const MainTemplate: FC<OwnToProps> = ({children}) => {
    return (
        <div className={styles.main}>
            <Header/>
            <main className={styles.mainContainer}>
                {children}
            </main>
            <Footer/>
        </div>
    );
};

export default MainTemplate;