import React, { FC, useEffect, useState } from "react";
import { ethers } from "ethers";
import styles from "./home.page.logged.in.module.scss";
import { useAuth } from "../../context/auth.context";
import metamask from "./../../assets/images/icon/metamask-icon.svg";
import { CustomButton, SubContainer } from "../../components";
import tiersIcon from "./../../assets/images/icon/tiers-icon.svg";
import arrowIcon from "./../../assets/images/icon/arrow.svg";
import polygon from "../../assets/images/logo/polygon.svg";
import { useWindowSize } from "../../hooks/useWindowSize.hook";
import { TokenService } from "../../services/token.service";
import CONTRACT_ABI from "./../../abi/cardona-ContractRegister-abi.json";
import { WalletService } from "../../services/wallet.service";
import { getRegister } from "../../utils";
import { NodeERC721A__factory } from "../../typechain-types";
import { PrivateSaleService } from "../../services/privateSale.service";

const BRIDGE_URL = process.env.REACT_APP_BRIDGE_URL;

const HomePageLoggedIn: FC = () => {
    const { address, logout, isAuth } = useAuth();
    const { width } = useWindowSize();
    const [soldNodes, setSoldNodes] = useState<number>(0);
    // const [isWhiteListed, setIsWhiteListed] = useState<boolean>(false);

    useEffect(() => {
        const getTotalSupply = async (ret: number) => {
            const register = getRegister();
            try {
                const nodeERC721A = NodeERC721A__factory.connect(
                    await register.contractByName("NodeERC721A"),
                    register.runner
                );
                const totalSupply = await nodeERC721A.totalSupply();
                setSoldNodes(Number(totalSupply));
            } catch {
                await new Promise((f) => setTimeout(f, 500));
                ret++;
                if (ret < 5) {
                    getTotalSupply(ret);
                }
            }
        };
        getTotalSupply(0);
    }, []);

    // useEffect(() => {
    //     const whiteListCheck = async () => {
    //         if (!isAuth) {
    //             return;
    //         }
    //         const privateSaleService = new PrivateSaleService();
    //         const { Permissions } =
    //             await privateSaleService.getPrivateSalePermissions();
    //         if (Permissions.length > 0) {
    //             setIsWhiteListed(true);
    //         }
    //     };
    //     whiteListCheck();
    // }, []);

    return (
        <div className={styles.home}>
            <h1 style={{ marginBottom: 10 }}>
                WIREX PAY <span>Node Sale!</span>
            </h1>
            <h2 style={{ textAlign: "center" }}>
                <span>{soldNodes} Nodes Sold</span>
            </h2>
            <div style={{ textAlign: "center", paddingBottom: 10 }}>
                <p style={{ marginBottom: 10 }}>
                    The much-anticipated Wirex Pay Node Sale is officially
                    underway!
                </p>
                <p style={{ marginBottom: 10 }}>
                    Whitelist Sale Launch : August 5th, at 12:00 PM UTC
                </p>
                <p style={{ marginBottom: 10 }}>
                    Public Sale Launch: August 8th, at 12:00 PM UTC
                </p>
            </div>
            <div className={styles.walletInfo}>
                <div className={styles.walletInfoItem}>
                    <img
                        className={styles.polygon}
                        src={polygon}
                        alt="polygon"
                    />
                    <img src={metamask} alt="metamask" />
                    <div className={styles.address}>
                        <p>My Wallet</p>
                        <h4>
                            {address?.slice(
                                0,
                                (width as number) > 567 ? 30 : 17
                            )}
                            ...
                        </h4>
                    </div>
                </div>
                <button onClick={logout}>Disconnect</button>
            </div>
            <SubContainer>
                <div className={styles.subContainer}>
                    <h3>Start exploring and purchasing nodes</h3>
                    <div style={{ maxWidth: "500px" }}>
                        <p
                            style={{
                                color: "#82827F",
                                fontSize: "18px",
                                fontWeight: 400,
                                textAlign: "center",
                                marginBottom: "2.5rem",
                            }}
                        >
                            We accept tokens on the Polygon POS. To bridge ETH
                            or ERC20 tokens, use the "Bridge Tokens" button. You
                            can also use third-party bridges -{" "}
                            <a
                                style={{ color: "#82827F" }}
                                href={"https://portfolio.metamask.io/bridge"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                metamask bridge
                            </a>
                            ,{" "}
                            <a
                                style={{ color: "#82827F" }}
                                href={
                                    "https://app.debridge.finance/?inputChain=1&outputChain=137&inputCurrency=&outputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&dlnMode=simple"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                debridge
                            </a>{" "}
                            or{" "}
                            <a
                                style={{ color: "#82827F" }}
                                href={
                                    "https://jumper.exchange/?fromChain=1&fromToken=0x0000000000000000000000000000000000000000&toChain=137&toToken=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                jumper
                            </a>
                            .
                        </p>
                    </div>
                    {/* {isWhiteListed ? (
                        <div className={styles.btnContainer}>
                            <CustomButton
                                url="/private-sale"
                                iconPosition="left"
                                padding={"20px 36px"}
                                color="#FFFFFF"
                                text="Whitelist Sale"
                                backgroundColor="#009B5B"
                                type="link"
                            />
                        </div>
                    ) : (
                        <></>
                    )} */}
                    <div className={styles.btnContainer}>
                        <CustomButton
                            icon={tiersIcon}
                            url="/tiers"
                            iconPosition="left"
                            color="#212323"
                            text="View Tiers"
                            backgroundColor="#EFF1F2"
                            type="link"
                        />
                    </div>
                    <div className={styles.btnContainer}>
                        <CustomButton
                            icon={arrowIcon}
                            url={BRIDGE_URL}
                            iconPosition="right"
                            color="#212323"
                            text="Bridge Tokens"
                            backgroundColor="#EFF1F2"
                            type="externalLink"
                        />
                    </div>
                    <div className={styles.btnContainer}>
                        <CustomButton
                            icon={arrowIcon}
                            iconPosition="right"
                            color="#212323"
                            text="My Purchases"
                            backgroundColor="#EFF1F2"
                            url="/purchases"
                            type="link"
                        />
                    </div>
                    <div className={styles.btnContainer}>
                        <CustomButton
                            icon={arrowIcon}
                            url={"https://docs.wirexpaychain.com/"}
                            iconPosition="right"
                            color="#212323"
                            text="About Wirex Pay"
                            backgroundColor="#EFF1F2"
                            type="externalLink"
                        />
                    </div>
                    <div className={styles.btnContainer}>
                        <CustomButton
                            icon={arrowIcon}
                            url={
                                "https://docs.wirexpaychain.com/node-sale/wirex-pay-node-purchase-guide"
                            }
                            iconPosition="right"
                            color="#212323"
                            text="Node Purchase Guide"
                            backgroundColor="#EFF1F2"
                            type="externalLink"
                        />
                    </div>
                </div>
            </SubContainer>
        </div>
    );
};

export default HomePageLoggedIn;
