import React, { FC, useEffect, useState } from "react";
import styles from "./transfer.license.page.module.scss";
import {
    CustomButton,
    CustomInput,
    GoBackButton,
    Message,
    Popup,
    SubContainer,
    WalletInfo,
} from "../../components";
import { useParams } from "react-router-dom";
import tierIcon from "../../assets/images/icon/tier-icon.svg";
import nodeIcon from "./../../assets/images/icon/node-icon.svg";
import errorIcon from "./../../assets/images/icon/error-small-icon.svg";
import nodeSmallIcon from "./../../assets/images/icon/nodes.svg";
import successIcon from "./../../assets/images/icon/success.svg";
import arrowBottom from "../../assets/images/icon/arrow-bottom.svg";
import { useAuth } from "../../context/auth.context";
import { OffchainService } from "../../services/offchain.service";
import { PurchasesService } from "../../services/purchases.service";
import { formatToEth } from "../one-tier-page";

const TransferLicensePage: FC = () => {
    const { id } = useParams();

    const [value, setValue] = useState<string>("0");
    const [address, setAddress] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { purchase } = useAuth();
    const submit = () => {
        setIsOpen(false);
        setIsSuccess(true);
    };

    // useEffect(() => {
    //     const getAllApprovals = async () => {
    //         let offchainService = new OffchainService();
    //         let res = await offchainService.getApprovals();
    //         console.log(res)
    //     }
    //
    //     getAllApprovals()
    // }, []);

    useEffect(() => {
        const fetchPurchases = async () => {
            const purchasesService = new PurchasesService();
            let response = await purchasesService.getPurchaseOwnerAddress(
                process.env.REACT_APP_ADMIN_ADDRESS!,
                id
            );
        };

        // fetchPurchases();
    }, []);

    return (
        <div className={styles.transferLicense}>
            <GoBackButton />
            <div className={styles.transferLicenseContainer}>
                <h1>Transfer License</h1>
                <WalletInfo />
                <div className={styles.subContainer}>
                    <SubContainer>
                        <div className={styles.transferLicenseMain}>
                            <div className={styles.header}>
                                <img src={tierIcon} alt="tier icon" />
                                <span>Tier {purchase?.id}</span>
                            </div>
                            {isSuccess ? (
                                <div className={styles.successContainer}>
                                    <img src={successIcon} alt="success" />
                                    <h3>Licenses successfully transfered</h3>
                                    <div className={styles.successTable}>
                                        <div>
                                            <span>Transfer Amount</span>
                                            <span className={styles.nodeCount}>
                                                <img
                                                    src={nodeSmallIcon}
                                                    alt="node"
                                                />
                                                {value}
                                            </span>
                                        </div>
                                        <div className={styles.arrowBottom}>
                                            <img
                                                src={arrowBottom}
                                                alt="arrowBottom"
                                            />
                                        </div>
                                        <div>
                                            <span>Recipient Address</span>
                                            <span>{address}</span>
                                        </div>
                                    </div>
                                    <div className={styles.btnContainerSuccess}>
                                        <CustomButton
                                            text="To My Purchases"
                                            backgroundColor="#EFF1F2"
                                            type="link"
                                            url="/purchases"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <h3>
                                        Select the nodes amount you wish to
                                        transfer
                                    </h3>
                                    <div className={styles.tierBalance}>
                                        <img src={nodeIcon} alt="node" />
                                        <h1>
                                            {+formatToEth(
                                                purchase.amount_payed
                                            ) /
                                                +formatToEth(
                                                    +purchase.tier
                                                        .price_native_token
                                                )}{" "}
                                            Nodes
                                        </h1>
                                        <p>Your Tier balance</p>
                                    </div>
                                    <div className={styles.inputContainer}>
                                        <CustomInput
                                            min={
                                                +formatToEth(
                                                    purchase.amount_payed
                                                ) /
                                                    +formatToEth(
                                                        +purchase.tier
                                                            .price_native_token
                                                    ) >
                                                1
                                                    ? "2"
                                                    : "1"
                                            }
                                            half={
                                                +formatToEth(
                                                    purchase.amount_payed
                                                ) /
                                                    +formatToEth(
                                                        +purchase.tier
                                                            .price_native_token
                                                    ) >
                                                1
                                                    ? String(
                                                          +formatToEth(
                                                              purchase.amount_payed
                                                          ) /
                                                              +formatToEth(
                                                                  +purchase.tier
                                                                      .price_native_token
                                                              ) /
                                                              2
                                                      )
                                                    : "1"
                                            }
                                            max={
                                                +formatToEth(
                                                    purchase.amount_payed
                                                ) /
                                                    +formatToEth(
                                                        +purchase.tier
                                                            .price_native_token
                                                    ) >
                                                1
                                                    ? String(
                                                          +formatToEth(
                                                              purchase.amount_payed
                                                          ) /
                                                              +formatToEth(
                                                                  +purchase.tier
                                                                      .price_native_token
                                                              )
                                                      )
                                                    : "1"
                                            }
                                            purchaseInput
                                            arrowBottomIcon
                                            value={value}
                                            setValue={setValue}
                                            type="number"
                                            label="To Recipient's Address"
                                            purchaseValue={address}
                                            purchaseSetValue={setAddress}
                                        />
                                    </div>
                                    {isError && (
                                        <div className={styles.errorContainer}>
                                            <Message
                                                fontSize={16}
                                                icon={errorIcon}
                                                message="Transfer transaction failed. Please try again later."
                                            />
                                        </div>
                                    )}
                                    <div className={styles.btnContainer}>
                                        <CustomButton
                                            onClick={() => setIsOpen(true)}
                                            isDisable={!value || !address}
                                            text={
                                                !value
                                                    ? "Enter an Amount"
                                                    : !address
                                                    ? "Enter an Address"
                                                    : "Confirm Transfer"
                                            }
                                            color="#FFF"
                                            backgroundColor={
                                                !value || !address
                                                    ? "#DCDEDC"
                                                    : "#009B5B"
                                            }
                                            type="button"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </SubContainer>
                </div>
            </div>
            <Popup
                isOpen={isOpen}
                isWait={false} //TODO: add  wait state according to tx.wait
                onClose={() => setIsOpen(false)}
                name="Confirm Nodes Transfer"
                onConfirmName={"Confirm Transfer"}
                onConfirm={submit}
            >
                <div className={styles.popupContent}>
                    <h4>
                        You are about to transfer <span>{value} Nodes </span>to{" "}
                        <span>{address}</span>. This transaction will be
                        processed securely in <span>Network Name</span>
                    </h4>
                    <div className={styles.nodeContainer}>
                        <img src={nodeSmallIcon} alt="node" />
                        <h2>Transfer {value} Nodes</h2>
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default TransferLicensePage;
