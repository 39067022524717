import React, {useEffect, useRef, useState} from 'react';
import styles from './activity.module.scss';
import arrow from './../../assets/images/icon/accordion-arrow.svg';
import eth from './../../assets/images/logo/etherium.svg';
import polygon from './../../assets/images/logo/polygon.svg';
import rightArrow from './../../assets/images/icon/right-arrow-transaction.svg';
import successIcon from './../../assets/images/icon/success-icon.svg';
import Message from "../message";

interface AccordionItemProps {
    title: string;
    date: string;
    status: string;
    iconFrom: string;
    iconTo: string;
    fromToken: string;
    toToken: string;
    fromNetwork: string;
    toNetwork: string;
    amount: number;
    gasFee: number;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
                                                         title,
                                                         date,
                                                         status,
                                                         iconFrom,
                                                         iconTo,
                                                         fromToken,
                                                         toToken,
                                                         fromNetwork,
                                                         toNetwork,
                                                         amount,
                                                         gasFee
                                                     }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState('0px');
    const detailsRef = useRef<HTMLDivElement>(null);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (detailsRef.current) {
            setHeight(isOpen ? `${detailsRef.current.scrollHeight}px` : '0px');
        }
    }, [isOpen]);

    const statusStyle = (status: string) => {
        switch (status) {
            case 'Finalized':
                return {color: '#00D250', backgroundColor: "#00D25033"};
            case 'Processing':
                return {color: '#FFFFFF', backgroundColor: "#B6BAB6"};
            case 'Initiated':
                return {color: '#212323', backgroundColor: "#EFF1F2"};
        }
    }

    return (
        <div className={styles.accordionItem}>
            <div className={styles.header} onClick={toggleAccordion}>
                <div className={styles.headerInfo}>
                    <div className={styles.headerIcon}>
                        <img className={styles.iconFrom} src={iconFrom} alt=""/>
                        <img className={styles.iconTo} src={iconTo} alt=""/>
                    </div>
                    <div className={styles.headerDescription}>
                        <h4 className={styles.title}>{title}</h4>
                        <p className={styles.date}>{date}</p>
                    </div>
                </div>
                <div style={statusStyle(status)} className={styles.status}>{status}</div>
            </div>
            {status === 'Finalized' && <div className={styles.messageContainer}>
                <Message
                    fontSize={16}
                    backgroundColor="#00D25033"
                    color="#212323"
                    icon={successIcon}
                    message={`Tokens successfully bridged to ${toNetwork}.`}
                />
            </div>}
            <div className={styles.details} style={{height}}>
                <div ref={detailsRef} className={styles.detailsInner}>
                    <div className={styles.transactionInfoContainer}>
                        <img className={styles.image} src={rightArrow} alt="rightArrow"/>
                        <div className={styles.fromContainer}>
                            <p>From Network</p>
                            <div className={styles.iconContainer}>
                                <img src={eth} alt="eth"/>
                                <span>{fromNetwork}</span>
                            </div>
                        </div>
                        <div className={styles.toContainer}>
                            <p>Bridge To</p>
                            <div className={styles.iconContainer}>
                                <img src={polygon} alt="polygon"/>
                                <span>{toNetwork}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.transactionInfoContainer}>
                        <img className={styles.image} src={rightArrow} alt="rightArrow"/>
                        <div className={styles.fromContainer}>
                            <p>From Token</p>
                            <div className={styles.iconContainer}>
                                <img src={eth} alt="eth"/>
                                <span>{fromToken}</span>
                            </div>
                        </div>
                        <div className={styles.toContainer}>
                            <p>To Token</p>
                            <div className={styles.iconContainer}>
                                <img src={polygon} alt="polygon"/>
                                <span>{toToken}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.orderInfoContainer}>
                        <div className={styles.orderInfoItem} style={{borderBottom: '2px dashed rgb(220, 224, 220)'}}>
                            <div>
                                <p className={styles.name}>Amount</p>
                            </div>
                            <div className={styles.valueContainer}>
                                <img src={polygon} alt="polygon"/>
                                <span className={styles.value}>{amount} xETH</span>
                            </div>
                        </div>
                        <div className={styles.orderInfoItem}>
                            <div>
                                <p className={styles.gasFeeName}>Estimated Gas Fee</p>
                            </div>
                            <div className={styles.valueContainer}>
                                <img src={eth} alt="eth"/>
                                <span className={styles.gasFee}>{gasFee} xETH</span>
                            </div>
                        </div>
                    </div>
                    {/*{details}*/}
                </div>
            </div>
            <div className={styles.toggleButton} onClick={toggleAccordion}>
                <img className={`${styles.arrow} ${isOpen ? styles.arrowOpen : ''}`} src={arrow} alt="arrow"/>
                <span>{isOpen ? "Hide Details" : "See Details"}</span>
            </div>
        </div>
    );
};

export default AccordionItem;