import React, { FC } from "react";
import styles from "./one.purchases.module.scss";
import tierIcon from "./../../assets/images/icon/tier-icon.svg";
import nodeIcon from "../../assets/images/icon/node-icon.svg";
import polygon from "../../assets/images/logo/polygon.svg";
import lockedIcon from "../../assets/images/icon/locked-icon.svg";
import { CustomButton } from "../index";
import { useAuth } from "../../context/auth.context";
import { formatToEth } from "../../pages/one-tier-page";
import { Purchase } from "../../services/purchases.service";

interface OwnToProps {
    purchase: Purchase & { total: number };
}

const OnePurchases: FC<OwnToProps> = ({ purchase }) => {
    const { tier, total } = purchase;
    const { savePurchase } = useAuth();
    const amount = total * +formatToEth(+tier.price_native_token);
    return (
        <div
            onClick={() => savePurchase(purchase)}
            className={styles.purchaseItem}
        >
            <div className={styles.purchaseContent}>
                <div className={styles.purchaseHeader}>
                    <img src={tierIcon} alt="tierIcon" />
                    <p>Tier {tier?.id ?? ""}</p>
                </div>
                <div className={styles.purchaseInfo}>
                    <img src={nodeIcon} alt="node icon" />
                    <h2>{total} Nodes</h2>
                    <p>{+formatToEth(+tier.price_native_token)} ETH per Node</p>
                </div>
                <div className={styles.total}>
                    <p>Total Price</p>
                    <p className={styles.totalValue}>
                        <img src={polygon} alt="polygon" />
                        {amount.toFixed(2)} ETH
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OnePurchases;
