import React, {FC} from 'react';
import styles from './footer.module.scss';
import facebook from './../../assets/images/social/facebook.svg';
import google from './../../assets/images/social/google.svg';
import instagram from './../../assets/images/social/instagram.svg';
import linkedin from './../../assets/images/social/linkedin.svg';

const social = [
    {img: facebook, url: '#'},
    {img: google, url: '#'},
    {img: instagram, url: '#'},
    {img: linkedin, url: '#'},
]
const Footer: FC = () => {
    return (
        <footer className={styles.footer}>
            {/*<div className={styles.privacyLinks}>*/}
            {/*    <span>© Wirex Limited, {new Date().getFullYear()}.</span>*/}
            {/*    <a href="#">Terms & Condions </a>*/}
            {/*    <a href="#">Privacy Policy</a>*/}
            {/*</div>*/}
            {/*<div className={styles.socialLinks}>*/}
            {/*    {social.map(({img, url}, index) => <a key={index} href={url}><img src={img} alt="social"/></a>)}*/}
            {/*</div>*/}
        </footer>
    );
};

export default Footer;