import { AuthService } from "./auth.service";

export class PriceService {
  private AuthService = new AuthService();

  public async getPriceProof(tokenAddress: string) {
    const token = await this.AuthService.getAuthToken();
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL_NODE_SALE}/price_proof?token_address=${tokenAddress}`,
      {
        headers: {
          bearer: token as string,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Failed.");
    }
    const proof = await res.json();
    const unixTS = Math.floor(new Date(proof.valid_til).valueOf() / 1000);
    proof.valid_til = unixTS;
    return proof as PriceProof;
  }
}

type PriceProof = {
  token_address: string;
  rate: number;
  valid_til: number;
  sig: string;
};
