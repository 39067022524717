import React, { useEffect, useState } from "react";
import styles from "./purchases.page.module.scss";
import { GoBackButton, WalletInfo } from "../../components";
import OnePurchases from "../../components/one-purchases";
import { Purchase, PurchasesService } from "../../services/purchases.service";
import SkeletonTier from "../../components/skeleton-tier";
import { useAuth } from "../../context/auth.context";

type PurchMap = {
    [id: string]: Purchase & { total: number };
};
const mapPurchases = (pr: Purchase[]) => {
    const pMap: PurchMap = {};
    const pArr: (Purchase & { total: number })[] = [];
    for (const p of pr) {
        if (pMap[p.tier.id]) {
            pMap[p.tier.id].total++;
        } else {
            pMap[p.tier.id] = { ...p, total: 1 };
        }
    }
    for (const p in pMap) {
        pArr.push(pMap[p]);
    }
    return pArr;
};

const PurchasesPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { address } = useAuth();
    const [purchases, setPurchases] = useState<
        (Purchase & { total: number })[]
    >([]);

    useEffect(() => {
        const fetchPurchases = async (address: string) => {
            setIsLoading(true);
            const purchasesService = new PurchasesService();
            let response = await purchasesService.getPurchaseOwnerAddress(
                address
            );
            if (response.purchases.length > 0) {
                const purchases = mapPurchases(response.purchases);
                console.log(purchases);
                setPurchases(purchases);
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        };

        if (address) fetchPurchases(address);
    }, [address]);

    return (
        <div className={styles.purchases}>
            <GoBackButton />
            <div className={styles.purchasesContainer}>
                <h1>My Purchases</h1>
                <WalletInfo />
                <div className={styles.purchasesInfo}>
                    <h3>Purchases</h3>
                    <div className={styles.purchasesInfoItem}>
                        {purchases.length > 0 ? (
                            purchases.map(
                                (el: Purchase & { total: number }) => (
                                    <div key={el?.id}>
                                        <OnePurchases purchase={el} />
                                    </div>
                                )
                            )
                        ) : isLoading ? (
                            <h2>Loading purchase history...</h2>
                        ) : (
                            <h2>No purchases found</h2>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchasesPage;
